import { ModuleDefinition } from 'core';
import { BookingI18n } from './BookingI18n';
import BookingPageList, { FindCampaignPage } from './BookingPageList';
import BookingReducer from './BookingReducer';

const BookingModule = (): ModuleDefinition => {
    return {
        key: 'BookingModule',
        i18nMap: BookingI18n,
        pageList: BookingPageList,
        reducer: BookingReducer,
        index: FindCampaignPage,
    };
};
export default BookingModule;
