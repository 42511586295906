import { ModuleDefinition } from 'core';
import { DoohMapI18n } from './DoohMapI18n';
import DoohMapPageList, { ScreenStatusMapPage } from './DoohMapPageList';
import DoohMapReducer from './DoohMapReducer';

const DoohMapModule = (): ModuleDefinition => {
    return {
        key: 'DoohMapModule',
        i18nMap: DoohMapI18n,
        pageList: DoohMapPageList,
        reducer: DoohMapReducer,
        index: ScreenStatusMapPage,
    };
};
export default DoohMapModule;
