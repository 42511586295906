import { Col, Radio, RadioChangeEvent, Row, Slider, Space } from 'antd';
import { Block, PrimaryFilledPanel } from 'component';
import { useAppDispatch } from 'core';
import { useEffect, useMemo, useState } from 'react';
import { ScreenStatusMapMdl } from '../ScreenStatusMapMdl';

const DelayFilterPanel = () => {
    const dispatch = useAppDispatch();
    const [uniteDelai, setUniteDelai] = useState(60);
    const [delai, setDelai] = useState(0);

    useEffect(() => {
        dispatch(ScreenStatusMapMdl.setDelay(delai * uniteDelai));
    }, [dispatch, delai, uniteDelai]);

    const changerUniteDelai = (e: RadioChangeEvent) => {
        const ud = +e.target.value;
        setUniteDelai(ud);
        setDelai(ud === 60 ? 15 : 1);
    };

    const changerDelai = (value) => {
        setDelai(value);
    };
    const MinutesSlider = useMemo(() => {
        const marks = {
            15: '15',
            30: '30',
            45: '45',
            60: '60',
            75: '75',
            90: '90',
        };
        return <Slider min={15} max={90} marks={marks} onChange={changerDelai} step={15} />;
    }, []);

    const HeuresSlider = useMemo(() => {
        const marks = {
            1: '1',
            2: '2',
            4: '4',
            8: '8',
            12: '12',
            16: '16',
            20: '20',
            24: '24',
        };

        return <Slider min={1} max={24} marks={marks} onChange={changerDelai} />;
    }, []);
    const JoursSlider = useMemo(() => {
        const marks = {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
        };
        return <Slider min={1} max={7} marks={marks} onChange={changerDelai} />;
    }, []);

    return (
        <PrimaryFilledPanel title="delay.filter">
            <Block>
                <Space direction="vertical">
                    <Row>
                        <Col span={24}>
                            <Radio.Group onChange={changerUniteDelai} value={uniteDelai} optionType="button" buttonStyle="solid">
                                <Radio value={60}>Minutes</Radio>
                                <Radio value={3600}>Heures</Radio>
                                <Radio value={86400}>Jours</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {uniteDelai === 60 && MinutesSlider}
                            {uniteDelai === 3600 && HeuresSlider}
                            {uniteDelai === 86400 && JoursSlider}
                        </Col>
                    </Row>
                </Space>
            </Block>
        </PrimaryFilledPanel>
    );
};

export default DelayFilterPanel;
