import { Avatar, Col, Modal, Row, Space } from 'antd';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useI18n } from 'core';
import styled from 'styled-components';

const RootComponent = styled(Modal)`
    .ant-modal-content {
        padding: 0;
        border-radius: 6px;
        .ant-modal-header {
            padding: 10px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
            background-color: #f9f9f9;
            border-bottom: 1px solid #ddd;
            .ant-modal-title {
                font-weight: 500;
                color: ${(props) => props.theme.token.colorPrimary};
                font-size: 26px;
                margin-top: 10px;
            }
        }
        .ant-modal-body {
            padding: 10px 20px;
        }
        .ant-modal-footer {
            padding: 10px;
            background-color: #fdfcfa;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-top: 1px solid #ddd;
        }
    }
`;

const SAvatar = styled(Avatar)`
    background-color: #fefefe;
    border: 1px solid #ddd;
    margin: 0 5px 5px 0;
    svg {
        margin-top: 1px;
        fill: ${(props) => props.theme.token.colorPrimary};
        width: 22px;
    }
`;

const SHeader = styled(Col)`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
`;

const SBody = styled(Col)`
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
`;

const SError = styled(Col)`
    font-weight: 700;
    font-size: 18px;
    color: red;
    margin-bottom: 10px;
`;

const InfoDialog = ({ visible, name, label = null, icon = null, header = null, closeAction = null, width = 520, children }) => {
    const { i18n } = useI18n();
    const getTitle = () => {
        return (
            <span>
                {icon && <SAvatar shape="circle" src={icon} size={32} />}
                {label || i18n(name)}
            </span>
        );
    };

    const getHeader = () => {
        if (header) {
            return (
                <Row>
                    <SHeader span="24">{header}</SHeader>
                </Row>
            );
        }
    };

    const getFooter = () => {
        return (
            <Space>
                <PrimaryFilledButton name="close" action={closeAction} />
            </Space>
        );
    };

    return (
        <RootComponent open={visible} title={getTitle()} footer={getFooter()} width={width} maskClosable={false} onCancel={closeAction}>
            {getHeader()}
            <Row>
                <SBody span="24">{children}</SBody>
            </Row>
        </RootComponent>
    );
};

export default InfoDialog;
