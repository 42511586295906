import { ModuleDefinition } from 'core';
import { JobsI18n } from './JobsI18n';
import JobsPageList, { FindJobPage } from './JobsPageList';
import JobsReducer from './JobsReducer';

const JobsModule = (): ModuleDefinition => {
    return {
        key: 'JobsModule',
        i18nMap: JobsI18n,
        pageList: JobsPageList,
        reducer: JobsReducer,
        index: FindJobPage,
    };
};
export default JobsModule;
