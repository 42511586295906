import { AuthContextProvider } from './auth/AuthContext';
import initAxios from './axios/axios.config';
import { AppConfigType, AppContextProvider } from './context/AppContext';
import { I18nContextProvider } from './i18n/I18nContext';
import { DynamicStoreProvider } from './redux/DynamicStoreContext';
import AppPrivateRoutes from './routes/AppPrivateRoutes';
import ErrorBoundary from './routes/ErrorBoundary';
import AntdThemeProvider from './theme/AntdThemeProvider';

const CoreApp = ({ config, children }: { config: AppConfigType; children: React.ReactNode }) => {
    initAxios(config.apiTimeout);

    return (
        <AuthContextProvider>
            <DynamicStoreProvider>
                <ErrorBoundary>
                    <AppContextProvider config={config}>
                        <AppPrivateRoutes config={config}>
                            <AntdThemeProvider theme={config.theme} locale={config.locale}>
                                <I18nContextProvider config={config}> {children} </I18nContextProvider>
                            </AntdThemeProvider>
                        </AppPrivateRoutes>
                    </AppContextProvider>
                </ErrorBoundary>
            </DynamicStoreProvider>
        </AuthContextProvider>
    );
};

export default CoreApp;
