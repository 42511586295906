import { Col, Form, Row } from 'antd';
import { ChampDate, ChampOuiNon, ChampTexte, ConfirmDialog, Formulaire, RowSpacing, useOnChange } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedCampaignListSeclector } from '../FindCampaignMdl';

const UpdateDayPartDialog = ({ visible, setVisible }) => {
    const selectedCampaignList = useSelector(selectedCampaignListSeclector);
    const [saveCluster, setSaveCluster] = useState(false);
    const [dayPartTable, setDayPartTable] = useState(null);
    const [form] = Form.useForm();

    const close = () => {
        setVisible(false);
    };
    const confirm = () => {
        setVisible(false);
    };

    useOnChange('createCluster', form, (value) => {
        setSaveCluster(value);
    });

    const recalc = () => {
        const startDate = form.getFieldValue('startDate');
        const endDate = form.getFieldValue('endDate');

        if (!startDate || !endDate) return null;

        let start = dayjs(startDate);
        const end = dayjs(endDate);
        const days = [];

        while (start.isBefore(end) || start.isSame(end, 'day')) {
            days.push(start.clone());
            start = start.add(1, 'day');
        }

        const table = days.map((date, index) => (
            <div key={index}>
                <span>{date.format('MM/DD/YYYY')}</span>
                <input type="text" id={`day${index + 1}_start`} value="09:00" placeholder="09:00" />
                <input type="text" id={`day${index + 1}_end`} value="21:00" placeholder="21:00" />
                <br />
            </div>
        ));
        setDayPartTable(table);
    };

    useOnChange('startDate', form, (value) => {
        recalc();
    });

    useOnChange('endDate', form, (value) => {
        recalc();
    });

    //
    return (
        <ConfirmDialog name="updateDayPart" visible={visible} closeAction={close} confirmAction={confirm} width={1200}>
            <Row>
                <Col span="24">
                    <PrimaryOutlinedPanel>
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampOuiNon nom="createCluster" />
                            <ChampTexte nom="clusterName" invisible={!saveCluster} />
                        </Formulaire>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
            <RowSpacing />
            <Row gutter={20}>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Day Part to Add">
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampTexte nom="name" />
                            <ChampTexte nom="weight" />
                            <ChampDate nom="startDate" />
                            <ChampDate nom="endDate" />
                        </Formulaire>
                        {dayPartTable}
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Selected Campaign List">
                        <Table data={selectedCampaignList}>
                            <Column name="id" />
                            <Column name="name" />
                            <Column name="status" />
                            <Column name="zipcode" />
                        </Table>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
        </ConfirmDialog>
    );
};

export default UpdateDayPartDialog;
