import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { IPage, PaginationMapper } from 'core';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { IScreen, IScreenPaginatedList, IScreenQuery } from './ScreenDataModel';


const list = async (currentPage): Promise<PaginatedList<IScreen>> => {
    const response = await axios.get(`${API_URL}/screens?page=${currentPage}`);
    const list = response.data?.rows?.map((screen: IScreen) => {
        return { ...screen, lat: +screen.lat, lng: +screen.lng };
    });
    return { list, pagination: { currentPage, rowPerPage: 100, totalRow: response.data?.count } };
};


const get = async (screenId): Promise<IScreen> => {
    const response = await axios.get(`${API_URL}/screens/${screenId}`);
    const screen = response.data;
    const g = screen.geolocation.substring(1, screen.geolocation.length - 1).split(',');
    return { lat: parseFloat(g[1]), lng: parseFloat(g[0]), ...screen };
};
const find = async (screenQuery: IScreenQuery, currentPage: number) => {
    const { pageNumber, pageSize } = PaginationMapper.creerSimplePageble(currentPage);
    const screenPage: IPage<IScreen> = (await axios.post<IPage<IScreen>>(`${API_URL}/find?page=${pageNumber}&size=${pageSize}`, screenQuery)).data;
    const screenPaginatedList: IScreenPaginatedList = {} as IScreenPaginatedList;
    screenPaginatedList.list = screenPage.content;
    screenPaginatedList.pagination = PaginationMapper.createPagination(screenPage);
    return screenPaginatedList;
};

const ScreenService = {
    list,
    get,
    find
};

export default ScreenService;