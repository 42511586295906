import { Menu } from 'antd';
import { useAppContext, useI18n } from 'core';
import useAuthContext from 'core/auth/AuthContext';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

const MenuModule = ({ open }) => {
    const { i18n } = useI18n();
    const config = useAppContext();
    const { role } = useAuthContext();
    const params = useParams();
    const location = useLocation();

    const [items, setItems] = useState([]);
    const [selected, setSelected] = useState([]);
    const [opened, setOpened] = useState([]);

    const pageToElementMenu = (page, children?) => {
        const path = page.toPath(params);
        const disabled = path.indexOf('undefined') > -1;

        return {
            key: page.menu,
            label: <Link to={disabled ? '#' : path}>{i18n(page.key)}</Link>,
            icon: page.icone,
            disabled,
            children,
        };
    };

    useEffect(() => {
        if (role && config.roleAreaMap[role]?.moduleList?.length) {
            const listeModule = config.roleAreaMap[role]?.moduleList;

            const lst = listeModule?.map((module) => {
                if (module.index.menu) {
                    if (module.subModuleList?.length) {
                        return pageToElementMenu(
                            module.index,
                            module.subModuleList.map((sousModule) => sousModule.index.menu && pageToElementMenu(sousModule.index))
                        );
                    }
                    return pageToElementMenu(module.index);
                }
            });

            setItems(lst.filter(Boolean) || []);
        }
    }, [role, config, params, i18n]);

    useEffect(() => {
        const selected = [];
        const opened = [];

        items.forEach((item) => {
            if (location.pathname.indexOf(item.key) > -1) {
                opened.push(item.key);
                if (location.pathname === item.key) {
                    selected.push(item.key);
                }
            }

            if (item.children) {
                item.children.forEach((subItem) => {
                    if (location.pathname.indexOf(subItem.key) > -1) {
                        opened.push(item.key);
                        if (location.pathname === subItem.key) {
                            selected.push(subItem.key);
                        }
                    }
                });
            }
        });

        setSelected(selected);
        setOpened(opened);
    }, [location, items]);

    const getMenu = useCallback(() => {
        if (open) {
            return <Menu items={items} mode="horizontal" openKeys={opened} selectedKeys={selected} />;
        } else {
            return <Menu items={items} mode="horizontal" defaultOpenKeys={opened} selectedKeys={selected} />;
        }
    }, [open, items, opened, selected]);

    return getMenu();
};

export default MenuModule;
