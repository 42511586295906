import { Layout } from 'antd';
import { MenuBloc } from 'component';
import useLayoutContext from './LayoutContext';

const LayoutHeader = () => {
    const { menuOpen } = useLayoutContext();
    return (
        <Layout.Header>
            <MenuBloc open={menuOpen} />
        </Layout.Header>
    );
};

export default LayoutHeader;
