import { IJob } from './JobDataModel';

const mapJob = (jobRep: any): IJob => {

    const newJob: IJob = {
        id: jobRep.id,
        nature: jobRep.nature,
        settings: jobRep.settings,
        status: jobRep.status,
        output: jobRep.output,
        createdAt: jobRep.createdAt,
        updatedAt: jobRep.updatedAt,
        startDate: jobRep.input.start_date,
        endDate: jobRep.input.end_date,
        campaignIds: jobRep.input.campaignIds
    };

    return newJob;

};

const mapJobList = (data: any[]): IJob[] => {
    return data.map(mapJob);
};

const JobMapper = {
    mapJob,
    mapJobList,
};

export default JobMapper;