import { APIProvider } from '@vis.gl/react-google-maps';
import { Col, Row } from 'antd';
import { GOOGLE_MAPS_API_KEY } from 'common/config/constants.config';
import { RowSpacing } from 'component';
import { useExecute } from 'core';
import { useEffect } from 'react';
import DelayFilterPanel from './element/DelayFilterPanel';
import GoogleMaps from './element/GoogleMaps';
import ListClusterPanel from './element/ListClusterPanel';
import ListScreenPanel from './element/ListScreenPanel';
import PositionFiltrePanel from './element/PositionFiltrePanel';
import StatusFilterPanel from './element/StatusFilterPanel';
import ScreenStatusMapCtrl from './ScreenStatusMapCtrl';

const ScreenStatusMapView = () => {
    const { execute } = useExecute();

    useEffect(() => {
        execute(ScreenStatusMapCtrl.listScreen);
    }, []);
    return (
        <Row>
            <Col span={4} style={{ padding: '20px' }}>
                <DelayFilterPanel />
                <RowSpacing />
                <StatusFilterPanel />
                <RowSpacing />
                <PositionFiltrePanel />
            </Col>
            <Col span={16}>
                <APIProvider apiKey={GOOGLE_MAPS_API_KEY}>
                    <GoogleMaps />
                </APIProvider>
            </Col>
            <Col span={4} style={{ padding: '20px' }}>
                <ListClusterPanel />
                <RowSpacing />
                <ListScreenPanel />
            </Col>
        </Row>
    );
};

export default ScreenStatusMapView;
