import { createContext, useContext, useState } from 'react';
import { ModuleDefinition } from '../routes/ModuleDefinition';

export type RoleAreaType = {
    moduleList?: ModuleDefinition[];
};

export interface AppConfigType {
    appName: string;
    locale: string;
    dateFormat: string;
    apiTimeout: number;
    theme: Record<string, any>;
    permissionMap: Record<string, string[]>;
    roleAreaMap: Record<string, RoleAreaType>;
    listReference: (params: any) => Promise<any>;
    initialized?: boolean;
    setInitialized?: (value: boolean) => void;
}

export const AppContext = createContext<AppConfigType | undefined>(undefined);

export const AppContextProvider = ({ config, children }) => {
    const [initialized, setInitialized] = useState(false);
    return <AppContext.Provider value={{ initialized, setInitialized, ...config }}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within a AppContext');
    }
    return context;
};

export default useAppContext;
