import { Col, Row } from 'antd';
import { PrimaryFilledPanel, Section } from 'component';
import ContentGraph from './element/ContentGraph';
import ContentTable from './element/ContentTable';

const ListContentView = () => {
    //
    return (
        <Section>
            <Row gutter={20}>
                <Col span="14">
                    <PrimaryFilledPanel title="content.list">
                        <ContentTable />
                    </PrimaryFilledPanel>
                </Col>
                <Col span="8">
                    <PrimaryFilledPanel title="content.graph">
                        <ContentGraph />
                    </PrimaryFilledPanel>
                </Col>
            </Row>
        </Section>
    );
};

export default ListContentView;
