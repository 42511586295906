import { Form } from 'antd';
import { ChampTexte, Column, Formulaire, InfoDialog, Tab, TabMenu, Table } from 'component';
import { useExecute } from 'core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { resultSelector, screenSeclector } from '../FindScreenMdl';

const ScreenDetailDialog = ({ visible, setVisible, screenId }) => {
    const [form] = Form.useForm();
    const screen = useSelector(screenSeclector);
    const result = useSelector(resultSelector);
    const { execute, success } = useExecute(result);

    useEffect(() => {
        form.setFieldsValue(screen);
    }, [success]);

    useEffect(() => {
        if (screenId && visible) {
            execute(FindScreenCtrl.getScreen, { screenId });
        }
    }, [screenId]);

    const close = () => {
        setVisible(false);
    };

    const reverseMinuteMask = (minute_mask) => {
        const dayNames = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const dayIntervals = minute_mask.split(';');
        const resultTable = [];

        dayIntervals.forEach((interval, index) => {
            const [startMinute, endMinute] = interval.split('-').map(Number);

            const startDay = Math.floor(startMinute / (24 * 60));
            const endDay = Math.floor(endMinute / (24 * 60));

            const startHour = Math.floor((startMinute % (24 * 60)) / 60);
            const startMin = startMinute % 60;

            const endHour = Math.floor((endMinute % (24 * 60)) / 60);
            const endMin = endMinute % 60;

            const startFormatted = `${String(startHour).padStart(2, '0')}:${String(startMin).padStart(2, '0')}`;
            const endFormatted = `${String(endHour).padStart(2, '0')}:${String(endMin).padStart(2, '0')}`;

            resultTable.push({
                day: dayNames[index],
                start: startFormatted,
                end: endFormatted,
            });
        });

        return resultTable;
    };

    const expandedRowRender = (dayPart) => {
        return (
            <Table data={reverseMinuteMask(dayPart.minute_mask)}>
                <Column name="day" />
                <Column name="start" />
                <Column name="end" />
            </Table>
        );
    };

    const rowExpandable = () => {
        return true;
    };

    //
    if (screen) {
        return (
            <InfoDialog name="screenDetail" visible={visible} closeAction={close} width={1200}>
                <TabMenu>
                    <Tab key="info">
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampTexte nom="id" disabled />
                            <ChampTexte nom="name" disabled />
                            <ChampTexte nom="zipcode" disabled />
                            <ChampTexte nom="frameNumber" disabled />
                            <ChampTexte nom="lat" disabled />
                            <ChampTexte nom="lng" disabled />
                            <ChampTexte nom="status" disabled />
                        </Formulaire>
                    </Tab>
                    <Tab key="frame">
                        <Table data={screen.metadata?.frameList}>
                            <Column name="active" boolean />
                            <Column name="name" />
                            <Column name="geometry" />
                            <Column name="width" />
                            <Column name="height" />
                        </Table>
                    </Tab>
                    <Tab key="dayPart">
                        <Table data={screen.metadata?.daypartList} expandable={{ expandedRowRender, rowExpandable, defaultExpandedRowKeys: ['0'] }}>
                            <Column name="active" boolean />
                            <Column name="name" />
                            <Column name="start_date" />
                            <Column name="end_date" />
                            <Column name="weight" />
                        </Table>
                    </Tab>
                </TabMenu>
            </InfoDialog>
        );
    }
};
export default ScreenDetailDialog;
