import { DeleteOutlined, PlusCircleFilled, SettingOutlined } from '@ant-design/icons';
import { Col, Form, Input, Row, Select, Space } from 'antd';
import { ChampListe, ChampOuiNon, ChampTexte, ConfirmDialog, Formulaire, RowSpacing, useOnChange } from 'component';
import IconButton from 'component/button/IconButton';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedScreenListSeclector } from '../FindScreenMdl';

const UpdateNameDialog = ({ visible, setVisible }) => {
    const selectedScreenList = useSelector(selectedScreenListSeclector);
    const [renamedScreenList, setRenamedScreenList] = useState([]);
    const [saveCluster, setSaveCluster] = useState(false);
    const [fields, setFields] = useState([{ id: 1, type: 'Text', value: '' }]);
    const [form] = Form.useForm();
    const screenFieldList = [
        { value: 'name', label: 'Old Name' },
        { value: 'zipcode', label: 'Zip Code' },
        { value: 'lat', label: 'Latitude' },
        { value: 'lng', label: 'Longitude' },
        { value: 'frameNumber', label: 'Frame Number' },
        { value: 'createdAt', label: 'Created At' },
        { value: 'updatedAt', label: 'Updated At' },
    ];

    const close = () => {
        setVisible(false);
    };

    const confirm = () => {
        setVisible(false);
    };

    useEffect(() => {
        const list = selectedScreenList.map((screen) => {
            return {
                ...screen,
                oldName: screen.name,
            };
        });
        setRenamedScreenList(list);
    }, [selectedScreenList]);

    const addField = () => {
        setFields([...fields, { id: fields.length + 1, type: 'Text', value: '' }]);
    };

    const apply = () => {
        const list = selectedScreenList.map((screen) => {
            const newName = fields
                .map((field) => {
                    if (field.type === 'Text') {
                        return field.value;
                    } else if (field.type === 'Field') {
                        return screen[field.value];
                    }
                    return '';
                })
                .join('');

            return {
                ...screen,
                oldName: screen.name,
                newName,
            };
        });
        setRenamedScreenList(list);
    };

    const removeField = (id) => {
        setFields(fields.filter((field) => field.id !== id).map((field, index) => ({ ...field, id: index + 1 })));
    };

    const handleTypeChange = (id, value) => {
        setFields(fields.map((field) => (field.id === id ? { ...field, type: value } : field)));
    };

    const handleValueChange = (id, value) => {
        setFields(fields.map((field) => (field.id === id ? { ...field, value } : field)));
    };

    useOnChange('createCluster', form, (value) => {
        setSaveCluster(value);
    });

    return (
        <ConfirmDialog name="updateName" visible={visible} closeAction={close} confirmAction={confirm} width={1200}>
            <Row gutter={20}>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Name Editor">
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampListe nom="pattern" liste={[{ code: 'Concat', label: 'Concat' }]} />
                        </Formulaire>
                        <div style={{ margin: '0px', border: '1px solid #EEE', padding: '10px' }}>
                            <Row gutter={20}>
                                <Col span="2">Arg</Col>
                                <Col span="8">Type</Col>
                                <Col span="8">Value</Col>
                                <Col span="6"></Col>
                            </Row>
                            {fields.map((field) => (
                                <Row gutter={[20, 5]} key={field.id}>
                                    <Col span="2">{field.id}</Col>
                                    <Col span="8">
                                        <Select
                                            value={field.type}
                                            onChange={(value) => handleTypeChange(field.id, value)}
                                            options={[
                                                { value: 'Field', label: 'Field' },
                                                { value: 'Text', label: 'Text' },
                                            ]}
                                            style={{ width: '100%' }}
                                        />
                                    </Col>
                                    <Col span="8">{field.type === 'Field' ? <Select value={field.value} onChange={(value) => handleValueChange(field.id, value)} options={screenFieldList} style={{ width: '100%' }} /> : <Input value={field.value} onChange={(e) => handleValueChange(field.id, e.target.value)} />}</Col>
                                    <Col span="6">
                                        <IconButton icon={<DeleteOutlined />} action={() => removeField(field.id)} />
                                    </Col>
                                </Row>
                            ))}
                        </div>
                        <RowSpacing />
                        <Space>
                            <PrimaryOutlinedButton name="addArg" icon={<PlusCircleFilled />} action={addField} />
                            <PrimaryOutlinedButton name="apply" icon={<SettingOutlined />} action={apply} />
                        </Space>
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Selected Screen List">
                        <Table data={renamedScreenList}>
                            <Column name="id" />
                            <Column name="oldName" />
                            <Column name="newName" />
                            <Column name="zipcode" />
                        </Table>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
            <RowSpacing />
            <Row>
                <Col span="24">
                    <PrimaryOutlinedPanel>
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampOuiNon nom="createCluster" />
                            <ChampTexte nom="clusterName" invisible={!saveCluster} />
                        </Formulaire>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
        </ConfirmDialog>
    );
};

export default UpdateNameDialog;
