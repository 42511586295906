import { ConfigProvider, theme } from 'antd';
import BaseBouton, { BtnProps } from './BaseButton';
import DisabledOutlinedButton from './DisabledOutlinedButton';

const PrimaryOutlinedButton = (props: BtnProps) => {
    const { token } = theme.useToken();
    const components = {
        Button: {
            defaultColor: token.colorPrimary,
            defaultBg: '#fff',
            defaultBorderColor: token.colorPrimary,
            defaultHoverColor: token.colorWarning,
            defaultHoverBg: '#fff',
            defaultHoverBorderColor: token.colorWarning,
        },
    };

    if (props.disabled) {
        return <DisabledOutlinedButton {...props} />;
    }
    return (
        <ConfigProvider theme={{ components }}>
            <BaseBouton {...props} />
        </ConfigProvider>
    );
};

export default PrimaryOutlinedButton;
