
export const JobsI18n = {
    FindJobPage: 'Jobs',
    jobs: 'Jobs',
    'FindJobUc.title': 'Submit Jobs',

    'job.resultList': 'Jobs List',

    campaignId: 'Campaign ID',
    dateRange: 'Date Range',

    add: 'Add',
    addJob: 'Add Job',
    nature: 'Nature',
    settings: 'Settings',
    output: 'Output',
    viewOutput: 'View Output',

};
