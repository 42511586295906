import { ReactNode, createContext, useContext } from 'react';

export interface IViewContextProps {
    uc: string;
}

const ViewContext = createContext<IViewContextProps | undefined>(undefined);

interface ViewContextProviderProps {
    uc: string;
    children: ReactNode;
}

export const ViewContextProvider: React.FC<ViewContextProviderProps> = ({ uc, children }) => {
    return <ViewContext.Provider value={{ uc }}>{children}</ViewContext.Provider>;
};

const useViewContext = () => {
    const context = useContext(ViewContext);
    if (context === undefined) {
        throw new Error('useViewContext must be used within a ViewContextProvider');
    }
    return context;
};

export default useViewContext;
