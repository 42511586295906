import { action } from 'core';
import JobService from 'model/job/JobService';
import { FindJobReq, FindJobRes } from './FindJobMdl';


const listJobImpl = async (request: FindJobReq, result: FindJobRes, thunkAPI) => {
    result.jobList = await JobService.list();
};

const FindJobCtrl = {
    listJob: action<FindJobReq, FindJobRes>(listJobImpl, 'FindJobCtrlCtrl/listJob'),
};

export default FindJobCtrl;
