import { ConfigProvider } from 'antd';
import BaseBouton, { BtnProps } from './BaseButton';

const DisabledOutlinedButton = (props: BtnProps) => {
    const components = {
        Button: {
            defaultColor: '#aaa',
            defaultBg: '#fff',
            defaultBorderColor: '#aaa',
            defaultHoverColor: '#999',
            defaultHoverBg: '#fff',
            defaultHoverBorderColor: '#999',
            defaultHoverCursor: 'not-allowed',
        },
    };
    return (
        <ConfigProvider theme={{ components }}>
            <BaseBouton {...props} />
        </ConfigProvider>
    );
};

export default DisabledOutlinedButton;
