import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IRootState } from '../redux/DynamicStore';
import { IErrorDescriptor, IInfoDescriptor } from './MessageDataModel';

const initialState = {
    infoDescriptor: null as IInfoDescriptor,
    viewErrorDescriptor: null as IErrorDescriptor,
    dialogErrorDescriptor: null as IErrorDescriptor,
    currentAction: {},
};
type MessageStateType = typeof initialState;

export const MessageSlice = createSlice({
    name: 'MessageMdl',
    initialState,
    reducers: {
        init: (state) => {
            return { ...initialState, actionEnCours: state.currentAction };
        },
        setCurrentAction: (state, action: PayloadAction<any>) => {
            let m = state.currentAction[action.payload.rid];
            if (!m) {
                m = [];
            }
            m.push(action.payload.actionName);
            state.currentAction[action.payload.rid] = m;
        },
        endAction: (state, action: PayloadAction<string>) => {
            state.currentAction = _.omit(state.currentAction, [action.payload]);
        },
        setInfoDescriptor: (state, action: PayloadAction<IInfoDescriptor>) => {
            state.infoDescriptor = action.payload;
        },
        setViewErrorDescriptor: (state, action: PayloadAction<IErrorDescriptor>) => {
            state.viewErrorDescriptor = action.payload;
        },
        setDialogErrorDescriptor: (state, action: PayloadAction<IErrorDescriptor>) => {
            state.dialogErrorDescriptor = action.payload;
        },
    },
});

const selectMessageMdlState = (state: IRootState) => state.messageMdl;

export const viewErrorDescriptorSelector = createSelector([selectMessageMdlState], (state: MessageStateType) => state?.viewErrorDescriptor);
export const dialogErrorDescriptorSelector = createSelector([selectMessageMdlState], (state: MessageStateType) => state?.dialogErrorDescriptor);
export const infoDescriptorSelector = createSelector([selectMessageMdlState], (state: MessageStateType) => state?.infoDescriptor);
export const currentActionSelector = createSelector([selectMessageMdlState], (state: MessageStateType) => state?.currentAction);

export const MessageMdl = MessageSlice.actions;

export default MessageSlice.reducer;
