import { Route } from 'react-router-dom';
import { ModuleDefinition } from './ModuleDefinition';
import Page from './Page';

export interface PageDefinition {
    key: string;
    toPath: (any?) => string;
    path: string;
    menu?: string;
    disabled?: () => false;
    open?: () => false;
    icon: JSX.Element;
    view: JSX.Element;
}

export const listRoutes = (moduleList: ModuleDefinition[]) => {
    return moduleList.map((module) => {
        if (module.subModuleList?.length) {
            return (
                <Route key={module.index.key} path={module.index.path} element={<Page>{module.index.view}</Page>}>
                    {listRoutes(module.subModuleList)}
                </Route>
            );
        }
        return module.pageList.map((page) => {
            return <Route key={page.key} path={page.path} element={<Page>{page.view}</Page>} />;
        });
    });
};

export const listReducer = (acc, moduleList: ModuleDefinition[]) => {
    return moduleList.reduce((acc, module) => {
        if (module.subModuleList?.length) {
            return { ...acc, ...module.reducer, ...listReducer(acc, module.subModuleList) };
        }
        return { ...acc, ...module.reducer };
    }, acc);
};
