import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { ICampaign } from './CampaignDataModel';

const list = async (currentPage): Promise<PaginatedList<ICampaign>> => {
    const response = await axios.get(`${API_URL}/campaigns?page=${currentPage}`);
    return { list: response.data?.rows, pagination: { currentPage, rowPerPage: 100, totalRow: response.data?.count } };
};

const get = async (campaignId): Promise<ICampaign> => {
    const response = await axios.get(`${API_URL}/campaigns/${campaignId}`);
    return response.data;
};



const CampaignService = {
    list,
    get
};

export default CampaignService;