import { PlusCircleFilled } from '@ant-design/icons';
import { DatePicker, Form, Select } from 'antd';
import { RowSpacing } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useAppDispatch, useExecute } from 'core';
import { useEffect } from 'react';
import { FindJobMdl } from '../FindJobMdl';

const CreateJobForm = () => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const [form] = Form.useForm();
    const options = [];
    for (let i = 10; i < 36; i++) {
        const firstPart = Math.floor(Math.random() * 900) + 100; // Generates a random number between 100 and 999
        const randomValue = `${firstPart} `; // Combines the two parts with a comma

        options.push({
            value: randomValue,
            label: randomValue,
        });
    }

    useEffect(() => {
        dispatch(FindJobMdl.init());
    }, []);

    const addJob = () => {
        console.log('addJob');
    };

    const { RangePicker } = DatePicker;

    return (
        <div style={{ width: '300px' }}>
            <Form form={form} layout="vertical">
                <Form.Item name="campaignId" label="Campaign Id">
                    <Select mode="tags" placeholder="Please select" options={options} />
                </Form.Item>
                <Form.Item name="dateRange" label="Date Range">
                    <RangePicker format="YYYY-MM-DD" name="dateRange" />
                </Form.Item>
                <Form.Item name="jobType" label="Job Type">
                    <Select options={[{ value: 'popAnalysis', label: 'Pop Analysis' }]} />
                </Form.Item>
            </Form>
            <RowSpacing />
            <div style={{ textAlign: 'right' }}>
                <PrimaryFilledButton icon={<PlusCircleFilled />} name="add" action={addJob} />
            </div>
        </div>
    );
};

export default CreateJobForm;
