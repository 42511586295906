import { Map, Marker, useMap } from '@vis.gl/react-google-maps';
import { useAppDispatch } from 'core';
import { IScreen } from 'model/screen/ScreenDataModel';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { centerSelector, delaySelector, locationListSelector, screenListSelector, ScreenStatusMapMdl, showOfflineSelector, showOnlineSelector, zoomSelector } from '../ScreenStatusMapMdl';
import InfoPopup from './InfoPopup';

const GoogleMaps = () => {
    const dispatch = useAppDispatch();
    const delay = useSelector(delaySelector);
    const screenList = useSelector(screenListSelector);
    const showOffline = useSelector(showOfflineSelector);
    const showOnline = useSelector(showOnlineSelector);
    const center = useSelector(centerSelector);
    const zoom = useSelector(zoomSelector);
    const locationList = useSelector(locationListSelector);
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [markersForScreen, setMarkersForScreen] = useState([]);
    const [markersForLocation, setMarkersForLocation] = useState([]);

    const dateInSecond = Date.now() / 1000;
    const statusDate = dateInSecond - delay;

    const map = useMap();

    const getIcon = (screen: IScreen) => {
        const lastUpdateInSecond = new Date(screen.status_last).getTime() / 1000;
        const status = statusDate < lastUpdateInSecond ? 'online' : 'offline';
        const markerIcon = status === 'online' ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png' : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png';
        return markerIcon;
    };
    useEffect(() => {
        const markerList = screenList.map((screen: IScreen) => {
            const lastUpdateInSecond = new Date(screen.status_last).getTime() / 1000;
            const status = statusDate < lastUpdateInSecond ? 'online' : 'offline';
            if (status === 'online' && !showOnline) {
                return null;
            }
            if (status === 'offline' && !showOffline) {
                return null;
            }
            return (
                <Marker //
                    position={screen}
                    icon={getIcon(screen)}
                    onClick={() => setSelectedScreen(screen)}
                />
            );
        });
        setMarkersForScreen(markerList);
    }, [screenList]);

    const handleZoomChanged = (mapInstance) => {
        const z = mapInstance.map.getZoom();
        dispatch(ScreenStatusMapMdl.setZoom(z));
    };

    useEffect(() => {
        if (locationList?.length > 0) {
            const markers = locationList?.map((location, index) => (
                <Marker
                    key={index}
                    position={location}
                    icon={'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'} // Example icon for uploaded locations
                />
            ));
            setMarkersForLocation(markers);
            const bounds = new window.google.maps.LatLngBounds();
            locationList.forEach((location) => {
                bounds.extend(location);
            });
            setBounds(bounds);
        } else {
            setMarkersForLocation([]);
            setBounds(null);
        }
    }, [locationList]);

    const getMarkerList = useCallback(() => {
        return [...markersForScreen, ...markersForLocation];
    }, [markersForScreen, markersForLocation]);

    useEffect(() => {
        if (bounds) {
            map.fitBounds(bounds);
        }
    }, [bounds]);

    return (
        <Map //
            style={{ width: '100%', height: '100vh' }}
            center={center}
            zoom={zoom}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            onZoomChanged={handleZoomChanged}
        >
            {getMarkerList()}
            {selectedScreen && <InfoPopup screen={selectedScreen} close={() => setSelectedScreen(null)} />}
        </Map>
    );
};

export default GoogleMaps;
