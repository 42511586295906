import { action } from 'core';
import ScreenService from 'model/screen/ScreenService';
import { FindScreenReq, FindScreenRes } from './FindScreenMdl';



const changePageImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    const { findScreenMdl } = thunkAPI.getState() as any;
    result.screenPaginatedList = await ScreenService.find(findScreenMdl.query, request.currentPage);
};

const findScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.screenPaginatedList = await ScreenService.list(request.currentPage);
};
const getScreenImpl = async (request: FindScreenReq, result: FindScreenRes, thunkAPI) => {
    result.screen = await ScreenService.get(request.screenId);
};

const FindScreenCtrl = {
    changePage: action<FindScreenReq, FindScreenRes>(changePageImpl, 'FindScreenCtrlCtrl/changePage'),
    findScreen: action<FindScreenReq, FindScreenRes>(findScreenImpl, 'FindScreenCtrlCtrl/findScreen'),
    getScreen: action<FindScreenReq, FindScreenRes>(getScreenImpl, 'FindScreenCtrlCtrl/getScreen'),
};

export default FindScreenCtrl;
