import _ from 'lodash';
import util from '../util/util';

const label = (key: string, i18nLabelMap: Record<string, string>, safe = true): string => {
    if (util.estNul(key)) {
        return safe ? '[]' : null;
    }
    switch (key) {
        case '_empty':
            return '\xA0';
        case 'Label':
            return 'Label';
        case 'code':
            return 'Code';
        default:
            if (key.startsWith('label')) {
                const filteredKey = key.charAt(5).toLowerCase() + key.slice(8);
                return i18nLabelMap[filteredKey] || (safe ? `[${filteredKey}]` : null);
            }
            return i18nLabelMap[key] || (safe ? `[${key}]` : null);
    }
};


const action = (key: string, i18nLabelMap: Record<string, string>): string => {
    if (util.estNul(key)) {
        return '';
    }

    return (
        label(key, i18nLabelMap, false) ||
        _.capitalize(
            key
                .split('/')
                .pop()
                .split(/(?=[A-Z])/)
                .join(' ')
        )
    );
};

const LabelMapper = {
    label,
    action,
};

export default LabelMapper;
