import { InfoWindow } from '@vis.gl/react-google-maps';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import { useI18n } from 'core';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ScreenStatusMapMdl } from '../ScreenStatusMapMdl';

export const Label = styled.div`
    font-weight: 500;
    font-size: 14px;
    padding-top: 10px;
`;
export const Value = styled.div`
    font-weight: 300;
    font-size: 18px;
`;

const InfoPopup = ({ screen, close }) => {
    const { i18n } = useI18n();
    const dispatch = useDispatch();
    const select = () => {
        dispatch(ScreenStatusMapMdl.addScreen(screen));
        close();
    };
    return (
        <InfoWindow position={screen} headerDisabled shouldFocus={true}>
            <div>
                <Label>{i18n('id')}</Label>
                <Value>{screen.id}</Value>
                <Label>{i18n('zipcode')}</Label>
                <Value>{screen.zipcode}</Value>
                <Label> {i18n('status')}</Label>
                <Value> {screen.status}</Value>
                <Label> {i18n('status_last')}</Label>
                <Value> {screen.status_last}</Value>
            </div>
            <div>
                <PrimaryOutlinedButton name="close" action={close} />
                <PrimaryFilledButton name="select" action={select} />
            </div>
        </InfoWindow>
    );
};

export default InfoPopup;
