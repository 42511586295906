import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { IJob } from './JobDataModel';
import JobMapper from './JobMapper';

const list = async (): Promise<IJob[]> => {
    const response = await axios.get(`${API_URL}/jobs`);
    return JobMapper.mapJobList(response.data);
};


const JobService = {
    list,
};

export default JobService;