import { DeleteOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown, Space } from 'antd';
import { RowSpacing } from 'component';
import LinkButton from 'component/button/LinkButton';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindScreenMdl, selectedScreenListSeclector } from '../FindScreenMdl';
import UpdateDayPartDialog from './UpdateDayPartDialog';
import UpdateNameDialog from './UpdateNameDialog';

const SelectedScreenTable = ({ viewScreen }) => {
    const dispatch = useAppDispatch();
    const selectedScreenList = useSelector(selectedScreenListSeclector);
    const [updateNameVisible, setUpdateNameVisible] = useState(false);
    const [updateDayPartVisible, setUpdateDayPartVisible] = useState(false);

    const removeScreen = (screen) => {
        dispatch(FindScreenMdl.removeScreen(screen));
    };
    const updateDayPart = (screen) => {
        setUpdateDayPartVisible(true);
    };
    const updateName = (screen) => {
        setUpdateNameVisible(true);
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <LinkButton name="updateDayPart" action={updateDayPart} />,
        },
        {
            key: '2',
            label: <LinkButton name="updateName" action={updateName} />,
        },
    ];

    return (
        <div>
            <Table data={selectedScreenList}>
                <Column name="id" />
                <Column name="name" />
                <Column name="status" />
                <Column name="zipcode" />
                <Column name="viewScreen" action={viewScreen} icon={<EyeOutlined />} />
                <Column name="removeScreen" action={removeScreen} icon={<DeleteOutlined />} />
            </Table>
            {selectedScreenList?.length > 0 && (
                <div style={{ textAlign: 'right' }}>
                    <RowSpacing />
                    <Space>
                        <Dropdown menu={{ items }} placement="bottomLeft">
                            <Button icon={<SyncOutlined />}>Mass Update</Button>
                        </Dropdown>
                    </Space>
                </div>
            )}
            <UpdateNameDialog visible={updateNameVisible} setVisible={setUpdateNameVisible} />
            <UpdateDayPartDialog visible={updateDayPartVisible} setVisible={setUpdateDayPartVisible} />
        </div>
    );
};

export default SelectedScreenTable;
