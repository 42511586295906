import _ from 'lodash';
import { Action } from 'redux';
import { IErrorDescriptor } from '../message/MessageDataModel';
import { MessageMdl } from '../message/MessageMdl';
import util from '../util/util';

function isRejectedAction(action: Action) {
    return action && action.type && action.type.endsWith('/rejected');
}

function isPendingAction(action: Action) {
    return action && action.type && action.type.endsWith('/pending');
}

function isFulfilledAction(action: Action) {
    return action && action.type && action.type.endsWith('/fulfilled');
}

export const serializeError = (value: any) => {
    if (util.nonNul(value) && value.isAxiosError && value.response) {
        return { data: value.response.data, status: value.response.status };
    } else if (util.nonNul(value) && value.errorFields) {
        return {
            status: -1,
            data: {
                errors: value.errorFields.map((err) => {
                    return { libelle: err.errors[0] };
                }),
            },
        };
    }
    return { message: String(value) };
};

const waxantMiddleWare = (store) => (next) => (action) => {
    const { error, payload } = action;
    const items = _.split(action.type, '/');
    if (isPendingAction(action)) {
        store.dispatch(MessageMdl.init());
    } else if (isFulfilledAction(action)) {
        store.dispatch(MessageMdl.setInfoDescriptor({ key: items[1], entityName: items[0], data: payload }));
    }

    if (isRejectedAction(action) && error) {
        const err = error?.message === 'Rejected' ? payload : error;
        let errorDescriptor: IErrorDescriptor = { code: payload };
        if (err.status) {
            switch (err.status) {
                case -1:
                    errorDescriptor = { code: 'error.validation.form', serverErrorList: err.data?.errors };
                    break;
                case 0:
                    errorDescriptor = { code: 'error.server.not.reachable' };
                    break;
                case 400:
                    errorDescriptor = { code: 'error.bad.request', error: err.data.code, serverErrorList: err.data?.errors };
                    break;
                case 404:
                    errorDescriptor = { code: 'error.url.not.found' };
                    break;
                case 500:
                    errorDescriptor = { code: 'error.server.error' };
                    break;
            }
        }

        store.dispatch(MessageMdl.setViewErrorDescriptor(errorDescriptor));
    }
    return next(action);
};

export default waxantMiddleWare;
