import { SettingOutlined } from '@ant-design/icons';
import { PageDefinition, ViewContextProvider } from 'core';
import FindJobView from '../uc/findJob/FindJobView';

export const FindJobPage: PageDefinition = {
    key: 'FindJobPage',
    path: '/jobs/findJob',
    toPath: () => '/jobs/findJob',
    icon: <SettingOutlined />,
    menu: 'jobs',
    view: (
        <ViewContextProvider uc="FindJobUc">
            <FindJobView />
        </ViewContextProvider>
    ),
};

const JobsPageList = [
    FindJobPage, //
];

export default JobsPageList;
