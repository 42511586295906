import { BtnProps } from 'component/button/BaseButton';
import IconButton from 'component/button/IconButton';
import { useHasRight } from 'core';

const IconAction = (props: BtnProps) => {
    const hasRight = useHasRight(props.name);
    return <IconButton {...props} visible={hasRight} />;
};

export default IconAction;
