import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { IContent } from 'model/content/ContentDataModel';
import ListContentCtrlCtrl from './ListContentCtrl';

export interface ListContentReq extends IRequest {
    dataForm?: any;
    currentPage?: number;
}

export interface ListContentRes extends IResult {
    contentList?: PaginatedList<IContent>;
}

const initialState = {
    result: {} as ListContentRes,
    contentList: [] as PaginatedList<IContent>,
};

type ListContentType = typeof initialState;

const SliceChercherIdentification = createSlice({
    name: 'ListContentMdl',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(ListContentCtrlCtrl.listContent.fulfilled, (state, action) => {
                state.result = action.payload;
                state.contentList = action.payload.contentList;
            })
            .addMatcher(isPending(ListContentCtrlCtrl.listContent), (state) => {
                state.result = {} as ListContentRes;
            })
            .addMatcher(isRejected(ListContentCtrlCtrl.listContent), (state) => {
                state.result = { rid: 'erreur' } as ListContentRes;
            });
    },
});

export const ListContentMdl = SliceChercherIdentification.actions;

const listContentMdlSelector = (state) => state.listContentMdl;
export const resultSelector = createSelector([listContentMdlSelector], (state: ListContentType) => state.result);
export const contentListSelector = createSelector([listContentMdlSelector], (state: ListContentType) => state.contentList);

export default SliceChercherIdentification.reducer;
