import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { MessageMdl } from '../message/MessageMdl';
import { serializeError } from './waxantMiddleWare';

export interface IRequest {
    uid?: string;
    rid?: string;
}

export interface IResult {
    rid?: string;
}

const action = <Req extends IRequest, Res extends IResult>(operation, actionName) => {
    return createAsyncThunk(actionName, async (request: Req, thunkAPI) => {
        const rid = request.rid ? request.rid : _.uniqueId();

        const x = { rid, actionName };
        await thunkAPI.dispatch(MessageMdl.setCurrentAction(x));
        const resultat = { rid } as Res;
        try {
            await operation({ rid, ...request }, resultat, thunkAPI);
        } catch (err) {
            await thunkAPI.dispatch(MessageMdl.endAction(rid));
            return thunkAPI.rejectWithValue(serializeError(err));
        }
        await thunkAPI.dispatch(MessageMdl.endAction(rid));
        return resultat;
    });
};

export const createRequest = (setRid, params = null) => {
    const uid = _.uniqueId();
    setRid(uid);
    return { rid: uid, ...params };
};

export default action;
