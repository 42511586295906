import { EnvironmentOutlined } from '@ant-design/icons';
import { PageDefinition, ViewContextProvider } from 'core';
import ScreenStatusMapView from '../uc/screenStatusMap/ScreenStatusMapView';

export const ScreenStatusMapPage: PageDefinition = {
    key: 'ScreenStatusMapPage',
    path: '/doohMap/screenStatus',
    toPath: () => '/doohMap/screenStatus',
    icon: <EnvironmentOutlined />,
    menu: 'doohMap',
    view: (
        <ViewContextProvider uc="ScreenStatusMapUc">
            <ScreenStatusMapView />
        </ViewContextProvider>
    ),
};

const DoohMapPageList = [
    ScreenStatusMapPage, //
];

export default DoohMapPageList;
