import { IErrorDescriptor, IErrorMessage, IServerError } from '../message/MessageDataModel';
import MappeurLibelle from './LabelMapper';

const basicErrorMap = {
    'error.bad.request': ['Invalid Data', 'Please correct the following errors:'],
    'error.validation.form': ['Invalid Data', 'Please correct the following errors:'],
    'error.server.not.reachable': ['Technical Problem', 'The server is not responding, please contact your administrator'],
    'error.url.not.found': ['Technical Problem', 'Resource not found, please contact your administrator'],
    'error.server.error': ['Technical Problem', 'Server Error, please contact your administrator'],
};

const findErrorLabel = (serverError: IServerError, i18nErrorMap: Record<string, string>, i18nLabelMap: Record<string, string>): string => {
    let es = i18nErrorMap[serverError.code];
    if (!es) {
        return serverError.label || serverError.code;
    }

    if (serverError.arguments?.length) {
        for (const arg of serverError.arguments) {
            let al = MappeurLibelle.label(arg, i18nLabelMap, false);

            if (!al && arg.includes('.')) {
                const idx = arg.indexOf('.');
                al = MappeurLibelle.label(arg.substring(idx + 1), i18nLabelMap, false);
            }

            if (al) {
                es += ` ${al}`;
            }
        }
    }
    return es;
};

const get = ({ code, serverErrorList, directErrorList, error }: IErrorDescriptor, i18nErrorMap: Record<string, string>, i18nLabelMap: Record<string, string>): IErrorMessage => {
    const e = basicErrorMap[code];
    if (e) {
        const errorMessage: IErrorMessage = { title: e[0], subTitle: e[1], errorList: [] };
        if (serverErrorList?.length) {
            errorMessage.errorList = serverErrorList.map((err) => findErrorLabel(err, i18nErrorMap, i18nLabelMap));
        }

        if (directErrorList?.length) {
            errorMessage.errorList.push(...directErrorList);
        }
        if (error) {
            const erreurTexte = i18nErrorMap[error] || `[${error}]`;
            errorMessage.errorList.push(erreurTexte);
        }

        return errorMessage;
    }
    return { title: 'ERROR', subTitle: JSON.stringify({ code, serverErrorList, directErrorList, error }), errorList: [] };
};
const ErrorMapper = {
    get,
};

export default ErrorMapper;
