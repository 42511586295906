import _ from 'lodash';
import { IInfoDescriptor } from '../message/MessageDataModel';
import util from '../util/util';
import LabelMapper from './LabelMapper';

const templateMap = {
    'default.modify': _.template('<%= entityLabel %> has been successfully modified'),
    'default.create': _.template('<%= entityLabel %> has been successfully created'),
    'default.delete': _.template('<%= entityLabel %> has been successfully deleted'),
    'default.validate': _.template('<%= entityLabel %> has been successfully validated'),
    'default.reject': _.template('<%= entityLabel %> has been successfully rejected'),
};

const get = (infoDescriptor: IInfoDescriptor, i18nMessageMap: Record<string, string>, i18nLabelMap: Record<string, string>): string | null => {
    if (util.estNul(infoDescriptor?.key)) {
        return null;
    }

    const { key, entityName } = infoDescriptor;
    const messageTypeKey = `${entityName}.${key}`;

    const message = i18nMessageMap[messageTypeKey];
    if (util.nonNul(message)) {
        return message;
    }
    const compiledTemplate = templateMap[messageTypeKey] || templateMap[`default.${key}`];
    if (compiledTemplate) {
        return compiledTemplate({ key, entityName, entityLabel: LabelMapper.label(entityName, i18nLabelMap) });
    } else {
        const reducedKey = key.split(/(?=[A-Z])/)[0];
        const deducedType = entityName.split(/(?=[A-Z])/);
        const compiledTemplateForReducedKey = templateMap[`default.${reducedKey}`];
        if (compiledTemplateForReducedKey) {
            return compiledTemplateForReducedKey({ key, entityName, entityLabel: entityName.substring(4 + deducedType[1].length) });
        }
    }

    return null;
};

const InfoMapper = {
    get,
};

export default InfoMapper;
