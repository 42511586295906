import { CloseSquareFilled } from '@ant-design/icons';

const NoOption = () => {
    return (
        <div>
            <CloseSquareFilled />
        </div>
    );
};

export default NoOption;
