import { Col, Row } from 'antd';
import { PrimaryFilledPanel, Section } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import { useState } from 'react';
import ScreenDetailDialog from './element/ScreenDetailDialog';
import ScreenFilter from './element/ScreenFilter';
import ScreenTable from './element/ScreenTable';
import SelectedScreenTable from './element/SelectedScreenTable';

const FindScreenView = () => {
    const [visible, setVisible] = useState(false);
    const [screenId, setScreenId] = useState(false);
    const viewScreen = (screen) => {
        setScreenId(screen.id);
        setVisible(true);
    };
    //
    return (
        <Section>
            <Row gutter={[20, 20]}>
                <Col span="24">
                    <PrimaryOutlinedPanel title="screen.filter">
                        <ScreenFilter />
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="16">
                    <PrimaryFilledPanel title="screen.resultList">
                        <ScreenTable viewScreen={viewScreen} />
                    </PrimaryFilledPanel>
                </Col>
                <Col span="8">
                    <PrimaryFilledPanel title="screen.selectedList">
                        <SelectedScreenTable viewScreen={viewScreen} />
                    </PrimaryFilledPanel>
                </Col>
            </Row>
            <Row>
                <Col span="4" offset="20">
                    <ScreenDetailDialog visible={visible} setVisible={setVisible} screenId={screenId} />
                </Col>
            </Row>
        </Section>
    );
};

export default FindScreenView;
