import useAuthContext from 'core/auth/AuthContext';
import AuthPage from 'core/auth/AuthPage';
import useAppContext, { AppConfigType } from 'core/context/AppContext';
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useDynamicStore from '../redux/DynamicStoreContext';
import { listReducer, listRoutes } from './PageDefinition';
import PageNotFound from './PageNotFound';
import PrivateRoute from './PrivateRoute';

const AppPrivateRoutes = ({ config, children }: { config: AppConfigType; children: React.ReactNode }) => {
    const { changeStore } = useDynamicStore();
    const { role } = useAuthContext();
    const [routes, setRoutes] = useState(null);
    const { setInitialized } = useAppContext();

    useEffect(() => {
        if (role && config.roleAreaMap[role]?.moduleList?.length) {
            const moduleList = config.roleAreaMap[role].moduleList;
            const allReducers = listReducer({}, moduleList);

            changeStore(allReducers);

            const routes = listRoutes(moduleList);
            setRoutes(routes);
            setInitialized(true);
        } else {
            changeStore({});
            setRoutes(null);
            setInitialized(false);
        }
    }, [role, config]);

    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Routes>
                <Route path="/" element={<PrivateRoute>{children}</PrivateRoute>}>
                    {routes}
                    <Route path="*" element={<PageNotFound />} />
                </Route>
                <Route path="/login" element={<AuthPage />} />
            </Routes>
        </BrowserRouter>
    );
};

export default AppPrivateRoutes;
