
export const ContentI18n = {
    ListContentPage: 'Content',
    'ListContentUc.title': 'Content',
    content: 'Content',
    'content.list': 'Content List',
    'content.graph': 'Content Graph',
    bscid: 'ID',
    container_id: 'Container ID',
    creation_tm: 'Creation Time',
};
