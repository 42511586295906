import { ConfigProvider, theme } from 'antd';
import BaseBouton, { BtnProps } from './BaseButton';
import DisabledFilledButton from './DisabledFilledButton';

const WarningFilledButton = (props: BtnProps) => {
    const { token } = theme.useToken();
    const components = {
        Button: {
            defaultColor: '#fff',
            defaultBg: token.colorWarning,
            defaultBorderColor: token.colorWarning,
            defaultHoverColor: '#fff',
            defaultHoverBg: 'red',
            defaultHoverBorderColor: 'red',
        },
    };

    if (props.disabled) {
        return <DisabledFilledButton {...props} />;
    }
    return (
        <ConfigProvider theme={{ components }}>
            <BaseBouton {...props} />
        </ConfigProvider>
    );
};

export default WarningFilledButton;
