import { Col, Row } from 'antd';
import { PrimaryFilledPanel, Section } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import CreateJobForm from './element/CreateJobForm';
import JobTable from './element/JobTable';

const FindJobView = () => {
    //
    return (
        <Section>
            <Row gutter={[20, 20]}>
                <Col span="16">
                    <PrimaryOutlinedPanel title="addJob">
                        <CreateJobForm />
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="8"></Col>
                <Col span="16">
                    <PrimaryFilledPanel title="job.resultList">
                        <JobTable />
                    </PrimaryFilledPanel>
                </Col>
                <Col span="8"></Col>
            </Row>
        </Section>
    );
};

export default FindJobView;
