export const theme = {
    token: {
        fontSize: 14,
        fontFamily: 'Roboto',
        borderRadius: 2,
        colorPrimary: '#7209B7',
        colorBgContainerDisabled: '#F0F0F0',
        colorTextDisabled: '#777',
    },
    components: {
        Layout: {
            bodyBg: '#fefefe',
            headerBg: '#f6f6f6',
            footerPadding: '5px',
            footerBg: '#eee',
        },
        Form: {
            verticalLabelPadding: '0',
            itemMarginBottom: '5px',
        },
        Menu: {
            itemBg: '#f6f6f6',
        },
    },
};
