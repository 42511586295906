import { createSelector, createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { ICluster } from 'model/cluster/ClusterDataModel';
import { IScreen } from 'model/screen/ScreenDataModel';
import ScreenStatusMapCtrl from './ScreenStatusMapCtrl';

export interface ScreenStatusMapReq extends IRequest {
    form?: any;
    clusterId?: string;
}

export interface ScreenStatusMapRes extends IResult {
    screenList?: IScreen[];
    clusterList?: ICluster[];
    cluster?: ICluster;
}

const initialState = {
    result: {} as ScreenStatusMapRes,
    screenList: [] as IScreen[],
    clusterList: [] as ICluster[],
    currentScreen: {} as IScreen,
    currentCluster: {} as ICluster,
    oldCluster: {} as ICluster,
    delay: 0,
    showOnline: true,
    showOffline: true,
    center: { lat: 50.8, lng: 4.4 } as any,
    zoom: 11,
    locationList: [] as any[],

};
type ScreenStatusMapType = typeof initialState;

const SliceScreenStatusMap = createSlice({
    name: 'ScreenStatusMapMdl',
    initialState,
    reducers: {
        addScreen(state, action) {
            state.currentScreen = action.payload;
            const liste = state.currentCluster.screenList ? [...state.currentCluster.screenList] : [];

            // Check if the screen already exists based on its ID
            const screenExists = liste.some(screen => screen.id === action.payload.id);

            if (!screenExists) {
                liste.push(action.payload);
                state.currentCluster.screenList = liste;
                state.currentCluster.changed = true;
            }
        },
        removeScreen(state, action) {
            state.currentCluster.screenList = state.currentCluster.screenList.filter(screen => screen.id !== action.payload.id);
            state.currentCluster.changed = true;
        },
        setCurrentCluster(state, action) {
            state.currentCluster = action.payload;
        },
        setDelay(state, action) {
            state.delay = action.payload;
        },
        setShowOnline(state, action) {
            state.showOnline = action.payload;
        },
        setShowOffline(state, action) {
            state.showOffline = action.payload;
        },
        setCenter(state, action) {
            state.center = action.payload;
        },
        setZoom(state, action) {
            state.zoom = action.payload;
        },
        setLocationList(state, action) {
            state.locationList = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.listScreen), (state, action) => {
                state.result = action.payload;
                state.screenList = action.payload.screenList;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.listCluster), (state, action) => {
                state.result = action.payload;
                state.clusterList = action.payload.clusterList;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.getCluster), (state, action) => {
                state.result = action.payload;
                state.currentCluster = action.payload.cluster;
                state.oldCluster = action.payload.cluster;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.addCluster), (state, action) => {
                state.result = action.payload;
                state.currentCluster = action.payload.cluster;
            })
            .addMatcher(isFulfilled(ScreenStatusMapCtrl.saveCluster), (state, action) => {
                state.result = action.payload;
            })
            .addMatcher(isPending(ScreenStatusMapCtrl.addCluster, ScreenStatusMapCtrl.saveCluster, ScreenStatusMapCtrl.getCluster, ScreenStatusMapCtrl.listScreen, ScreenStatusMapCtrl.listCluster), (state) => {
                state.result = {} as ScreenStatusMapRes;
            })
            .addMatcher(isRejected(ScreenStatusMapCtrl.addCluster, ScreenStatusMapCtrl.saveCluster, ScreenStatusMapCtrl.getCluster, ScreenStatusMapCtrl.listScreen, ScreenStatusMapCtrl.listCluster), (state) => {
                state.result = { rid: 'erreur' } as ScreenStatusMapRes;
            });
    },
});

export const ScreenStatusMapMdl = SliceScreenStatusMap.actions;

const screenStatusMapSelector = (state) => state.screenStatusMapMdl;
export const resultSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.result);
export const screenListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.screenList);
export const clusterListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.clusterList);
export const currentScreenSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.currentScreen);
export const currentClusterSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.currentCluster);
export const delaySelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.delay);
export const showOnlineSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.showOnline);
export const showOfflineSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.showOffline);
export const centerSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.center);
export const zoomSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.zoom);
export const locationListSelector = createSelector([screenStatusMapSelector], (state: ScreenStatusMapType) => state.locationList);



export default SliceScreenStatusMap.reducer;
