export const API_URL = 'https://doohboost.app/api';
//export const API = 'http://localhost:3000/api';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyDQ5s_0P1YqNaX-rIDmsaa66j3fGHlJS3I';
//AIzaSyDQ5s_0P1YqNaX-rIDmsaa66j3fGHlJS3I
export enum APP_MODULES {
}

export enum APP_EVENT {
}
