import { PlusCircleFilled } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import { ChampTexte, Column, Formulaire, PrimaryFilledPanel, RowSpacing, Table } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useAppDispatch, useExecute } from 'core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ScreenStatusMapCtrl from '../ScreenStatusMapCtrl';
import { clusterListSelector, resultSelector } from '../ScreenStatusMapMdl';

const ListClusterPanel = () => {
    const dispatch = useAppDispatch();
    const clusterList = useSelector(clusterListSelector);
    const result = useSelector(resultSelector);
    const [form] = Form.useForm();
    const { execute: executeNoResult } = useExecute();
    const { execute, success } = useExecute(result);

    const addCluster = () => {
        execute(ScreenStatusMapCtrl.addCluster, { form });
    };

    useEffect(() => {
        executeNoResult(ScreenStatusMapCtrl.listCluster);
    }, []);

    useEffect(() => {
        if (success) {
            form.resetFields();
            executeNoResult(ScreenStatusMapCtrl.listCluster);
        }
    }, [success]);

    const selectionChanged = (selectedItems) => {
        executeNoResult(ScreenStatusMapCtrl.getCluster, { clusterId: selectedItems[0].id });
    };

    return (
        <PrimaryFilledPanel title="cluster.list">
            <Table data={clusterList} onSelectionChange={selectionChanged} selectionType="radio">
                <Column name="id" />
                <Column name="name" />
            </Table>
            <RowSpacing />
            <Row gutter={5}>
                <Col flex="auto">
                    <Formulaire form={form}>
                        <ChampTexte name="name" placeholder="Add New Cluster" />
                    </Formulaire>
                </Col>
                <Col flex="none">
                    <PrimaryFilledButton action={addCluster} icon={<PlusCircleFilled />} />
                </Col>
            </Row>
        </PrimaryFilledPanel>
    );
};

export default ListClusterPanel;
/*
  const selectScreen = (cluster, index) => {
        dispatch(ScreenStatusMapMdl.setCurrentCluster(cluster));
        setSelectedIndex(index);
    };

    const unSelectScreen = () => {
        dispatch(ScreenStatusMapMdl.setCurrentCluster(null));
        setSelectedIndex(-1);
    };
    */
