import axios from 'axios';
import { API_URL } from 'common/config/constants.config';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { IContent } from './ContentDataModel';

const list = async (currentPage): Promise<PaginatedList<IContent>> => {
    const response = await axios.get(`${API_URL}/contents?page=${currentPage}`);
    return { list: response.data?.rows, pagination: { currentPage, rowPerPage: 100, totalRow: response.data?.count } };
};


const ContentService = {
    list,
};

export default ContentService;