import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRootState } from '../redux/DynamicStore';

const initialState = {
    i18nLabelMap: {} as Record<string, string>,
    i18nErrorMap: {} as Record<string, string>,
    i18nMessageMap: {} as Record<string, string>,
};
type I18nStateType = typeof initialState;

export const I18nSlice = createSlice({
    name: 'I18nMdl',
    initialState,
    reducers: {
        extendLabelMap: (state, action: PayloadAction<Record<string, string>>) => {
            const currentMap = { ...state.i18nLabelMap, ...action.payload };
            state.i18nLabelMap = currentMap;
        },
        extendErrorMap: (state, action: PayloadAction<Record<string, string>>) => {
            const currentMap = { ...state.i18nErrorMap, ...action.payload };
            state.i18nErrorMap = currentMap;
        },
        extendMessageMap: (state, action: PayloadAction<Record<string, string>>) => {
            const currentMap = { ...state.i18nMessageMap, ...action.payload };
            state.i18nMessageMap = currentMap;
        },
    },
});

const selectI18nMdlState = (state: IRootState) => state.i18nMdl;

export const i18nLabelMapSelector = createSelector([selectI18nMdlState], (state: I18nStateType) => state?.i18nLabelMap);
export const i18nErrorMapSelector = createSelector([selectI18nMdlState], (state: I18nStateType) => state?.i18nErrorMap);
export const i18nMessageMapSelector = createSelector([selectI18nMdlState], (state: I18nStateType) => state?.i18nMessageMap);

export const I18nMdl = I18nSlice.actions;

export default I18nSlice.reducer;
