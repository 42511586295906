import { BarChartOutlined, DeleteOutlined, EyeOutlined, UnorderedListOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Space } from 'antd';
import { RowSpacing } from 'component';
import LinkButton from 'component/button/LinkButton';
import PrimaryOutlinedButton from 'component/button/PrimaryOutlinedButton';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useAppDispatch } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { FindCampaignMdl, selectedCampaignListSeclector } from '../FindCampaignMdl';
import UpdateDayPartDialog from './UpdateDayPartDialog';
import UpdateNameDialog from './UpdateNameDialog';

const SelectedCampaignTable = ({ viewCampaign }) => {
    const dispatch = useAppDispatch();
    const selectedCampaignList = useSelector(selectedCampaignListSeclector);
    const [updateNameVisible, setUpdateNameVisible] = useState(false);
    const [updateDayPartVisible, setUpdateDayPartVisible] = useState(false);

    const removeCampaign = (campaign) => {
        dispatch(FindCampaignMdl.removeCampaign(campaign));
    };
    const updateDayPart = (campaign) => {
        setUpdateDayPartVisible(true);
    };
    const updateName = (campaign) => {
        setUpdateNameVisible(true);
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <LinkButton name="updateDayPart" action={updateDayPart} />,
        },
        {
            key: '2',
            label: <LinkButton name="updateName" action={updateName} />,
        },
    ];

    return (
        <div>
            <Table data={selectedCampaignList}>
                <Column name="id" />
                <Column name="name" />
                <Column name="start_date" date />
                <Column name="end_date" date />
                <Column name="viewCampaign" action={viewCampaign} icon={<EyeOutlined />} />
                <Column name="removeCampaign" action={removeCampaign} icon={<DeleteOutlined />} />
            </Table>
            {selectedCampaignList?.length > 0 && (
                <div style={{ textAlign: 'right' }}>
                    <RowSpacing />
                    <Space>
                        <PrimaryOutlinedButton name="viewReport" icon={<BarChartOutlined />} />
                        <PrimaryOutlinedButton name="checkIssues" icon={<UnorderedListOutlined />} />
                    </Space>
                </div>
            )}
            <UpdateNameDialog visible={updateNameVisible} setVisible={setUpdateNameVisible} />
            <UpdateDayPartDialog visible={updateDayPartVisible} setVisible={setUpdateDayPartVisible} />
        </div>
    );
};

export default SelectedCampaignTable;
