import { CheckSquareOutlined, EyeOutlined } from '@ant-design/icons';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useAppDispatch, useExecute } from 'core';
import { useSelector } from 'react-redux';
import FindCampaignCtrl from '../FindCampaignCtrl';
import { FindCampaignMdl, campaignPaginatedListSelector } from '../FindCampaignMdl';

const CampaignTable = ({ viewCampaign }) => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const campaignPaginatedList = useSelector(campaignPaginatedListSelector);

    const selectCampaign = (campaign) => {
        dispatch(FindCampaignMdl.selectCampaign(campaign));
    };

    const onPageChange = (newPage) => {
        execute(FindCampaignCtrl.listCampaign, { currentPage: newPage });
    };

    return (
        <Table data={campaignPaginatedList.list} pagination={campaignPaginatedList.pagination} onPageChange={onPageChange}>
            <Column name="id" />
            <Column name="name" />
            <Column name="start_date" date />
            <Column name="end_date" date />
            <Column name="viewCampaign" action={viewCampaign} icon={<EyeOutlined />} />
            <Column name="selectCampaign" action={selectCampaign} icon={<CheckSquareOutlined />} />
        </Table>
    );
};

export default CampaignTable;
