import { Navigate, useLocation } from 'react-router';
import SessionStorage from '../util/session.storage';
import util from '../util/util';

const PrivateRoute = ({ children }) => {
    const location = useLocation();

    if (util.estNul(SessionStorage.getToken()) && location.pathname !== '/login') {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};
export default PrivateRoute;
