import { ConfigProvider, theme } from 'antd';
import BaseBouton, { BtnProps } from './BaseButton';

const LinkButton = (props: BtnProps) => {
    const { token } = theme.useToken();
    const components = {
        Button: {
            defaultColor: '#aaa',
            defaultBg: 'transparent',
            defaultBorderColor: 'transparent',
            defaultHoverColor: token.colorPrimary,
            defaultHoverBg: 'transparent',
            defaultHoverBorderColor: 'transparent',
        },
    };

    return (
        <ConfigProvider theme={{ components }}>
            <BaseBouton {...props} />
        </ConfigProvider>
    );
};

export default LinkButton;
