import { Button, Result } from 'antd';

export default function PageNotFound() {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Page Not Found"
            extra={
                <Button type="primary">
                    <a href="/">Home</a>
                </Button>
            }
        />
    );
}
