import { createSelector, createSlice, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { IScreen, IScreenQuery } from 'model/screen/ScreenDataModel';
import FindScreenCtrl from './FindScreenCtrl';

export interface FindScreenReq extends IRequest {
    dataForm?: any;
    currentPage?: number;
    screenId?: string;
}

export interface FindScreenRes extends IResult {
    screenPaginatedList?: PaginatedList<IScreen>;
    query?: IScreenQuery;
    screen?: IScreen;
}

const initialState = {
    result: {} as FindScreenRes,
    screenPaginatedList: [] as PaginatedList<IScreen>,
    selectedScreenList: [] as IScreen[],
    screen: {} as IScreen,
    query: {} as IScreenQuery,
};

type FindScreenType = typeof initialState;

const SliceFindScreen = createSlice({
    name: 'FindScreenMdl',
    initialState,
    reducers: {
        init: (state) => {
            return { ...initialState };
        },
        selectScreen: (state, action: PayloadAction<IScreen>) => {
            state.selectedScreenList.push(action.payload);
            state.screenPaginatedList = { list: state.screenPaginatedList?.list.filter(screen => screen.id !== action.payload.id), pagination: state.screenPaginatedList?.pagination };
        },
        removeScreen: (state, action: PayloadAction<IScreen>) => {
            state.screenPaginatedList?.list.push(action.payload);
            state.selectedScreenList = state.selectedScreenList.filter(screen => screen.id !== action.payload.id);
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FindScreenCtrl.changePage.fulfilled, (state, action) => {
                state.result = action.payload;
                state.screenPaginatedList = action.payload.screenPaginatedList;
            })
            .addCase(FindScreenCtrl.findScreen.fulfilled, (state, action) => {
                state.result = action.payload;
                state.screenPaginatedList = action.payload.screenPaginatedList;
                state.query = action.payload.query;
            })
            .addCase(FindScreenCtrl.getScreen.fulfilled, (state, action) => {
                state.result = action.payload;
                state.screen = action.payload.screen;
            })
            .addMatcher(isPending(FindScreenCtrl.changePage, FindScreenCtrl.findScreen), (state) => {
                state.result = {} as FindScreenRes;
            })
            .addMatcher(isRejected(FindScreenCtrl.changePage, FindScreenCtrl.findScreen), (state) => {
                state.result = { rid: 'erreur' } as FindScreenRes;
            });
    },
});

export const FindScreenMdl = SliceFindScreen.actions;

const findScreenMdlSelector = (state) => state.findScreenMdl;
export const resultSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.result);
export const screenPaginatedListSelector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.screenPaginatedList);
export const selectedScreenListSeclector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.selectedScreenList);
export const screenSeclector = createSelector([findScreenMdlSelector], (state: FindScreenType) => state.screen);

export default SliceFindScreen.reducer;
