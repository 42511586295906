import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Space, Upload, UploadProps } from 'antd';
import { GOOGLE_MAPS_API_KEY } from 'common/config/constants.config';
import { Block, PrimaryFilledPanel, RowSpacing } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useAppDispatch } from 'core';
import Papa from 'papaparse';
import { ScreenStatusMapMdl } from '../ScreenStatusMapMdl';

const PositionFiltrePanel = () => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const handleUpload = (file): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: false,
                complete: (results) => {
                    resolve(results.data);
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    };

    const props: UploadProps = {
        name: 'file',
        headers: {
            authorization: 'authorization-text',
        },
        beforeUpload: (file) => {
            form.setFieldsValue({ file });
            return false;
        },
        onRemove: () => {
            form.setFieldsValue({ file: null });
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
                filterPositionByFile();
            }
            if (info.file.status === 'error') {
                console.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const filterPositionByFile = async () => {
        try {
            const addresses: any[] = await handleUpload(form.getFieldValue('file'));

            const geocodedLocations = await Promise.all(
                addresses.map(async (address) => {
                    try {
                        const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`);
                        const data = await response.json();
                        if (data.status === 'OK') {
                            return data.results[0].geometry.location;
                        } else {
                            console.log('Failed to geocode:', address, data.status);
                            return null;
                        }
                    } catch (err) {
                        console.error('Geocoding error:', err);
                        return null;
                    }
                })
            );
            const validLocations = geocodedLocations.filter((location) => location !== null);
            form.resetFields();
            dispatch(ScreenStatusMapMdl.setCenter(null));
            dispatch(ScreenStatusMapMdl.setLocationList(validLocations));
        } catch (error) {
            console.error('Error processing uploaded file:', error);
        }
    };

    const filterByOne = async () => {
        try {
            const address = form.getFieldValue('address');
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${GOOGLE_MAPS_API_KEY}`);
            const data = await response.json();
            if (data.status === 'OK') {
                const location = data.results[0].geometry.location;
                dispatch(ScreenStatusMapMdl.setCenter(location));
                dispatch(ScreenStatusMapMdl.setLocationList(null));
            } else {
                console.log('Geocoding failed:', data.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    const resetFilterByOne = () => {
        form.resetFields();
        dispatch(ScreenStatusMapMdl.setCenter(null));
    };

    return (
        <PrimaryFilledPanel title="position.filter">
            <Block>
                <Space direction="vertical">
                    <Row>
                        <Col span={24}>
                            <Form form={form} layout="vertical">
                                <Form.Item name="address" label="Search Nearby Following Address">
                                    <Input style={{ width: '100%' }} />
                                </Form.Item>
                            </Form>
                            <PrimaryFilledButton name="filterByOne" action={filterByOne} />
                            <PrimaryFilledButton name="resetFilterByOne" action={resetFilterByOne} />
                        </Col>
                        <Divider>Or</Divider>
                        <Col span={24}>
                            <Upload {...props}>
                                <Button icon={<UploadOutlined />}>Upload Adress List</Button>
                            </Upload>
                        </Col>
                    </Row>
                    <RowSpacing />
                </Space>
            </Block>
        </PrimaryFilledPanel>
    );
};

export default PositionFiltrePanel;
