
export const BookingI18n = {
    FindCampaignPage: 'Booking',
    booking: 'Booking',
    'FindCampaignUc.title': 'Find Campaigns',
    'campaign.filter': 'Campaign Filter',
    'campaign.resultList': 'Query Result - Unselected Campaign List',
    'campaign.selectedList': 'Selected Campaign List',
    id: 'Id',
    name: 'Name',
    find: 'Find',
    'viewCampaign': 'View',
    'selectCampaign': 'Select',
    'removeCampaign': 'Remove',
    campaignDetail: 'Campaign Detail',
    close: 'Close',
    'tab.shedule': 'Schedule List',
    performance: 'Performance',
    plays: 'Plays',
    imps: 'Impressions',
    sov: 'Share of Voice',
    creationTime: 'Creation Time',
    mime: 'Mime',
    size: 'Size',
    status: 'Status',
    viewReport: 'View Report',
    checkIssues: 'Check Issues',
    start_date: 'Start Date',
    end_date: 'End Date',


};
