import { createSelector, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { IJob } from 'model/job/JobDataModel';
import FindJobCtrlCtrl from './FindJobCtrl';

export interface FindJobReq extends IRequest {
    dataForm?: any;
}

export interface FindJobRes extends IResult {
    jobList?: IJob[];
}

const initialState = {
    result: {} as FindJobRes,
    jobList: [] as IJob[],
};

type FindJobType = typeof initialState;

const SliceFindJob = createSlice({
    name: 'FindJobMdl',
    initialState,
    reducers: {
        init: (state) => {
            return { ...initialState };
        },
    },
    extraReducers(builder) {
        builder
            .addCase(FindJobCtrlCtrl.listJob.fulfilled, (state, action) => {
                state.result = action.payload;
                state.jobList = action.payload.jobList;
            })
            .addMatcher(isPending(FindJobCtrlCtrl.listJob), (state) => {
                state.result = {} as FindJobRes;
            })
            .addMatcher(isRejected(FindJobCtrlCtrl.listJob), (state) => {
                state.result = { rid: 'erreur' } as FindJobRes;
            });
    },
});

export const FindJobMdl = SliceFindJob.actions;

const findJobMdlSelector = (state) => state.findJobMdl;
export const resultSelector = createSelector([findJobMdlSelector], (state: FindJobType) => state.result);
export const jobListSelector = createSelector([findJobMdlSelector], (state: FindJobType) => state.jobList);

export default SliceFindJob.reducer;
