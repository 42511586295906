import { Pie } from '@ant-design/plots';

const ContentGraph = () => {
    const config = {
        data: [
            { status: 'Expired', count: 52 },
            { status: 'Running', count: 28 },
            { status: 'Unused', count: 20 },
        ],
        angleField: 'count',
        colorField: 'status',
        innerRadius: 0.6,
        label: {
            text: 'count',
            style: {
                fontWeight: 'bold',
            },
        },
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },
    };
    return <Pie {...config} />;
};

export default ContentGraph;
