import { SearchOutlined } from '@ant-design/icons';
import { PageDefinition, ViewContextProvider } from 'core';
import FindScreenView from '../uc/findScreen/FindScreenView';

export const FindScreenPage: PageDefinition = {
    key: 'FindScreenPage',
    path: '/inventory/findScreen',
    toPath: () => '/inventory/findScreen',
    icon: <SearchOutlined />,
    menu: 'inventory',
    view: (
        <ViewContextProvider uc="FindScreenUc">
            <FindScreenView />
        </ViewContextProvider>
    ),
};

const InventoryPageList = [
    FindScreenPage, //
];

export default InventoryPageList;
