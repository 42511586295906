import { ModuleDefinition } from 'core';
import { InventoryI18n } from './InventoryI18n';
import InventoryPageList, { FindScreenPage } from './InventoryPageList';
import InventoryReducer from './InventoryReducer';

const InventoryModule = (): ModuleDefinition => {
    return {
        key: 'InventoryModule',
        i18nMap: InventoryI18n,
        pageList: InventoryPageList,
        reducer: InventoryReducer,
        index: FindScreenPage,
    };
};
export default InventoryModule;
