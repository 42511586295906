import { Form } from 'antd';
import { ChampDate, ChampTexte, Column, Formulaire, InfoDialog, RowSpacing, Tab, TabMenu, Table } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import { useExecute } from 'core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FindCampaignCtrl from '../FindCampaignCtrl';
import { campaignSelector, resultSelector } from '../FindCampaignMdl';

const CampaignDetailDialog = ({ visible, setVisible, campaignId }) => {
    const [form] = Form.useForm();
    const campaign = useSelector(campaignSelector);
    const result = useSelector(resultSelector);
    const { execute, success } = useExecute(result);

    useEffect(() => {
        console.log('campaign', campaign);
        form.setFieldsValue(campaign);
    }, [success]);

    useEffect(() => {
        if (campaignId && visible) {
            execute(FindCampaignCtrl.getCampaign, { campaignId });
        }
    }, [campaignId]);

    const close = () => {
        setVisible(false);
    };
    const rowExpandable = () => {
        return true;
    };

    const expandedContentRowRender = (bundle) => {
        return (
            <Table data={bundle.contentList}>
                <Column name="name" label="Content" />
                <Column name="creationTime" date />
                <Column name="mime" />
                <Column name="size" />
                <Column name="status" />
            </Table>
        );
    };

    const expandedRowRender = (schedule) => {
        return (
            <Table data={schedule.bundleList} expandable={{ expandedRowRender: expandedContentRowRender, rowExpandable, defaultExpandedRowKeys: ['0'] }}>
                <Column name="name" label="Bundle" />
            </Table>
        );
    };

    //
    if (campaign) {
        return (
            <InfoDialog name="campaignDetail" visible={visible} closeAction={close} width={1200}>
                <div style={{ maxHeight: '600px', overflow: 'auto' }}>
                    <TabMenu>
                        <Tab key="info">
                            <Formulaire form={form} nombreColonne={2}>
                                <ChampTexte nom="id" disabled />
                                <ChampTexte nom="name" disabled />
                                <ChampDate nom="start_date" disabled />
                                <ChampDate nom="end_date" disabled />
                            </Formulaire>
                            <RowSpacing />
                            <PrimaryOutlinedPanel title="performance">
                                <Formulaire form={form} nombreColonne={3}>
                                    <ChampTexte nom="performance.plays" disabled />
                                    <ChampTexte nom="performance.imps" disabled />
                                    <ChampTexte nom="performance.sov" disabled />
                                </Formulaire>
                            </PrimaryOutlinedPanel>
                        </Tab>
                        <Tab key="shedule">
                            <Table data={campaign.metadata?.scheduleList} expandable={{ expandedRowRender, rowExpandable, defaultExpandedRowKeys: ['0'] }}>
                                <Column name="name" />
                                <Column name="start_date" date />
                                <Column name="end_date" date />
                            </Table>
                        </Tab>
                    </TabMenu>
                </div>
            </InfoDialog>
        );
    }
};
export default CampaignDetailDialog;
