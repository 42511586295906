import { SwapRightOutlined, WarningOutlined } from '@ant-design/icons';
import { Col, Modal, Row } from 'antd';
import WarningFilledButton from 'component/button/WarningFilledButton';
import { IErrorMessage, MessageMdl, useAppDispatch, useI18n, util, viewErrorDescriptorSelector } from 'core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const SDialogErreur = styled(Modal)`
    padding: 0;
    .ant-modal-content {
        border-radius: 6px;
        padding: 0;
        .ant-modal-body {
            padding: 0;
        }
        .ant-modal-footer {
            display: none;
        }
    }
`;

export const SDialogErreurEntete = styled(Col)`
    background-color: #333;
    color: orange;
    padding: 60px 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    .icone {
        margin: auto;
        text-align: center;
        width: 70px;
        font-size: 70px;
        line-height: 30px;
        padding: 0;
    }
    .title {
        margin: auto;
        font-weight: 400;
        font-size: 30px;
        text-align: center;
        .anticon svg {
            width: 36px;
        }
    }
`;

export const SDialogErreurCorps = styled(Col)`
    font-weight: 500;
    font-size: 18px;
    padding: 20px 20px 60px 20px;
    position: relative;
    .title {
        color: orange;
        font-size: 24px;
        font-weight: 300;
    }
    .sousTitre {
        font-size: 18px;
    }
    .action {
        text-align: right;
    }
    .detail {
        font-size: 14px;
    }
`;

export const SDialogueErreurFooter = styled.div`
    text-align: right;
    position: absolute;
    bottom: 20px;
    right: 20px;
`;

const ErrorDialog = () => {
    const { i18nError } = useI18n();
    const dispatch = useAppDispatch();
    const viewErrorDescriptor = useSelector(viewErrorDescriptorSelector);
    const [errorMessage, setErrorMessage] = useState(null as IErrorMessage);

    useEffect(() => {
        if (util.nonNul(viewErrorDescriptor)) {
            setErrorMessage(i18nError(viewErrorDescriptor));
        }
    }, [viewErrorDescriptor]);

    const close = () => {
        dispatch(MessageMdl.init());
    };

    return (
        <SDialogErreur open={util.nonNul(viewErrorDescriptor)} closable={false} width={600} zIndex={9999}>
            {viewErrorDescriptor && (
                <Row>
                    <SDialogErreurEntete span="8">
                        <div className="icone">
                            <WarningOutlined />
                        </div>
                        <div className="title">Erreur</div>
                    </SDialogErreurEntete>

                    <SDialogErreurCorps span="16">
                        <div>
                            <div className="title">{errorMessage?.title}</div>
                            <div className="message">{errorMessage?.subTitle}</div>
                            {errorMessage?.errorList.map((e) => (
                                <div className="detail" key={e}>
                                    <SwapRightOutlined /> {e}
                                </div>
                            ))}
                        </div>
                        <SDialogueErreurFooter>
                            <WarningFilledButton action={close} label="Fermer" />
                        </SDialogueErreurFooter>
                    </SDialogErreurCorps>
                </Row>
            )}
        </SDialogErreur>
    );
};

export default ErrorDialog;
