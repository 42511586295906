import useAuthContext from 'core/auth/AuthContext';
import useAppContext from 'core/context/AppContext';
import { useNavigate, useParams } from 'react-router';

const useGoToModule = () => {
    const navigate = useNavigate();
    const params = useParams();
    const config = useAppContext();
    const { role } = useAuthContext();

    const parse = (nomModule, listeModule) => {
        for (const module of listeModule) {
            if (module.key === nomModule) {
                return module.index;
            } else if (module.listeSousModule?.length) {
                const result = parse(nomModule, module.listeSousModule);
                if (result) {
                    return result;
                }
            }
        }
    };

    return (nomModule: string, args?: any) => {
        const index = parse(nomModule, config.roleAreaMap[role]?.moduleList);
        if (index) {
            navigate(index.toPath({ ...args, ...params }));
        }
    };
};

export default useGoToModule;
