import { FilePdfOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import InTableViewAction from 'component/action/InTableViewAction';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useExecute } from 'core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import FindJobCtrl from '../FindJobCtrl';
import { jobListSelector } from '../FindJobMdl';

const JobTable = () => {
    const { execute } = useExecute();
    const jobList = useSelector(jobListSelector);

    useEffect(() => {
        execute(FindJobCtrl.listJob);
    }, []);

    const outputContent = (index, job) => {
        if (job.status === 'completed') {
            return <InTableViewAction name="viewOutput" icon={<FilePdfOutlined />} />;
        }
    };

    const statusContent = (index, job) => {
        if (job.status === 'completed') {
            return <Tag color="green">Completed</Tag>;
        } else if (job.status === 'progress') {
            return <Tag color="blue">Progress</Tag>;
        } else if (job.status === 'pending') {
            return <Tag color="orange">Pending</Tag>;
        } else if (job.status === 'cancelled') {
            return <Tag color="orange">Cancelled</Tag>;
        } else if (job.status === 'failed') {
            return <Tag color="red">Failed</Tag>;
        }
    };

    return (
        <Table data={jobList}>
            <Column name="id" />
            <Column name="nature" />
            <Column name="settings" />
            <Column name="startDate" />
            <Column name="endDate" />
            <Column name="status" custom content={statusContent} />
            <Column name="output" custom content={outputContent} />
        </Table>
    );
};

export default JobTable;
