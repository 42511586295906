import { CheckSquareOutlined, EyeOutlined } from '@ant-design/icons';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useAppDispatch, useExecute } from 'core';
import { useSelector } from 'react-redux';
import FindScreenCtrl from '../FindScreenCtrl';
import { FindScreenMdl, screenPaginatedListSelector } from '../FindScreenMdl';

const ScreenTable = ({ viewScreen }) => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const screenPaginatedList = useSelector(screenPaginatedListSelector);

    const actionChangementPage = (currentPage: number) => {
        execute(FindScreenCtrl.findScreen, { currentPage });
    };

    const selectScreen = (screen) => {
        dispatch(FindScreenMdl.selectScreen(screen));
    };
    return (
        <Table data={screenPaginatedList.list} pagination={screenPaginatedList.pagination} onPageChange={actionChangementPage}>
            <Column name="id" />
            <Column name="name" />
            <Column name="status" />
            <Column name="zipcode" />
            <Column name="viewScreen" action={viewScreen} icon={<EyeOutlined />} />
            <Column name="selectScreen" action={selectScreen} icon={<CheckSquareOutlined />} />
        </Table>
    );
};

export default ScreenTable;
