import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { ChampTexte, Formulaire } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useAppDispatch, useExecute } from 'core';
import { useEffect, useState } from 'react';
import FindCampaignCtrl from '../FindCampaignCtrl';
import { FindCampaignMdl } from '../FindCampaignMdl';

const CampaignFilter = () => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const [form] = Form.useForm();
    const [edit, setEdit] = useState(true);

    useEffect(() => {
        dispatch(FindCampaignMdl.init());
    }, []);

    const find = () => {
        execute(FindCampaignCtrl.listCampaign, { currentPage: 1 });
        setEdit(false);
    };
    const editQuery = () => {
        setEdit(true);
    };

    return (
        <div>
            {edit && (
                <Formulaire form={form}>
                    <ChampTexte nom="name" />
                </Formulaire>
            )}
            <div style={{ textAlign: 'right' }}>
                {edit && <PrimaryFilledButton icon={<SearchOutlined />} name="find" action={find} />}
                {!edit && <PrimaryFilledButton icon={<EditOutlined />} name="editQuery" action={editQuery} />}
            </div>
        </div>
    );
};

export default CampaignFilter;
