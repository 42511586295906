import styled from 'styled-components';

const RootComponent = styled.div`
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 4px;
`;

const Block = ({ style = null, padding = '10px', width = '100%', children }) => {
    return <RootComponent style={{ ...style, padding, width }}>{children}</RootComponent>;
};

export default Block;
