import { ModuleDefinition } from 'core';
import { ContentI18n } from './ContentI18n';
import ContentPageList, { ListContentPage } from './ContentPageList';
import ContentReducer from './ContentReducer';

const ContentModule = (): ModuleDefinition => {
    return {
        key: 'ContentModule',
        i18nMap: ContentI18n,
        pageList: ContentPageList,
        reducer: ContentReducer,
        index: ListContentPage,
    };
};
export default ContentModule;
