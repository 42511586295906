import { Spin } from 'antd';
import { currentActionSelector, useI18n } from 'core';
import _ from 'lodash';
import { CSSProperties, ReactNode, useCallback } from 'react';
import { useSelector } from 'react-redux';

const style = { spinner: { position: 'relative', marginTop: ' 200px', backgroundColor: '#eee', border: '2px solid #ddd', borderRadius: '8px' } as CSSProperties };

const Spinner = ({ children }) => {
    const { i18nLog } = useI18n();
    const currenAction = useSelector(currentActionSelector);

    const content = () => {
        const array = _.flatten(_.values(currenAction));
        const liste: ReactNode[] = array.map((actionName: string, i) => {
            return (
                <text x="0" y={30 * (i + 1)} className="wafa_sp2" key={i}>
                    {i18nLog(actionName)} ...
                </text>
            );
        });
        return <div style={style.spinner}>{liste}</div>;
    };

    const inAction = useCallback(() => {
        let test = false;

        for (const label of _.values(currenAction)) {
            if (!_.isNil(label)) {
                test = true;
                window.scrollTo(0, 0);
                break;
            }
        }

        return test;
    }, [currenAction]);

    return <Spin spinning={inAction()}>{children}</Spin>;
};

export default Spinner;
