import { createSelector, createSlice, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { IRequest, IResult } from 'core';
import { PaginatedList } from 'core/pagination/PaginationDataModel';
import { ICampaign, ICampaignQuery } from 'model/campaign/CampaignDataModel';
import FindCampaignCtrlCtrl from './FindCampaignCtrl';

export interface FindCampaignReq extends IRequest {
    dataForm?: any;
    currentPage?: number;
    campaignId?: string;
}

export interface FindCampaignRes extends IResult {
    campaignPaginatedList?: PaginatedList<ICampaign>;
    campaign?: ICampaign;
    query?: ICampaignQuery;
}

const initialState = {
    result: {} as FindCampaignRes,
    campaignPaginatedList: [] as PaginatedList<ICampaign>,
    campaign: {} as ICampaign,
    selectedCampaignList: [] as ICampaign[],
    query: {} as ICampaignQuery,
};

type FindCampaignType = typeof initialState;

const SliceFindCampaign = createSlice({
    name: 'FindCampaignMdl',
    initialState,
    reducers: {
        init: (state) => {
            return { ...initialState };
        },
        selectCampaign: (state, action: PayloadAction<ICampaign>) => {
            state.selectedCampaignList.push(action.payload);
            state.campaignPaginatedList = { list: state.campaignPaginatedList?.list.filter(campaign => campaign.id !== action.payload.id), pagination: state.campaignPaginatedList?.pagination };
        },
        removeCampaign: (state, action: PayloadAction<ICampaign>) => {
            state.campaignPaginatedList?.list.push(action.payload);
            state.selectedCampaignList = state.selectedCampaignList.filter(campaign => campaign.id !== action.payload.id);
        },
    },
    extraReducers(builder) {
        builder

            .addCase(FindCampaignCtrlCtrl.listCampaign.fulfilled, (state, action) => {
                state.result = action.payload;
                state.campaignPaginatedList = action.payload.campaignPaginatedList;
                state.query = action.payload.query;
            })
            .addCase(FindCampaignCtrlCtrl.getCampaign.fulfilled, (state, action) => {
                state.result = action.payload;
                state.campaign = action.payload.campaign;
            })
            .addMatcher(isPending(FindCampaignCtrlCtrl.listCampaign, FindCampaignCtrlCtrl.getCampaign), (state) => {
                state.result = {} as FindCampaignRes;
            })
            .addMatcher(isRejected(FindCampaignCtrlCtrl.listCampaign, FindCampaignCtrlCtrl.getCampaign), (state) => {
                state.result = { rid: 'erreur' } as FindCampaignRes;
            });
    },
});

export const FindCampaignMdl = SliceFindCampaign.actions;

const findCampaignMdlSelector = (state) => state.findCampaignMdl;
export const resultSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.result);
export const campaignPaginatedListSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.campaignPaginatedList);
export const selectedCampaignListSeclector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.selectedCampaignList);
export const campaignSelector = createSelector([findCampaignMdlSelector], (state: FindCampaignType) => state.campaign);

export default SliceFindCampaign.reducer;
