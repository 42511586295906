import { IPage, IPageable, IPagination } from './PaginationDataModel';

const DEFAULT_ROW_PER_PAGE = 10;
const createPagination = function (page: IPage<any>): IPagination {
    const pagination = {} as IPagination;
    pagination.totalRow = page.totalElements;
    pagination.rowPerPage = page.size ? page.size : DEFAULT_ROW_PER_PAGE;
    pagination.currentPage = page.number + 1;
    return pagination;
};

const createPageable = function (pagination: IPagination): IPageable {
    const pageable = {} as IPageable;
    pageable.pageNumber = (pagination?.currentPage || 1) - 1;
    pageable.pageSize = pagination.rowPerPage ? pagination.rowPerPage : DEFAULT_ROW_PER_PAGE;

    return pageable;
};

const creerSimplePageble = function (currentPage: number): IPageable {
    const pageable = {} as IPageable;
    pageable.pageNumber = (currentPage || 1) - 1;
    pageable.pageSize = DEFAULT_ROW_PER_PAGE;

    return pageable;
};

const PaginationMapper = {
    createPagination,
    createPageable,
    creerSimplePageble,
};

export default PaginationMapper;
