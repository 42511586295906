import { action } from 'core';
import CampaignService from 'model/campaign/CampaignService';
import { FindCampaignReq, FindCampaignRes } from './FindCampaignMdl';

const listCampaignImpl = async (request: FindCampaignReq, result: FindCampaignRes, thunkAPI) => {
    result.campaignPaginatedList = await CampaignService.list(request.currentPage);
};

const getCampaignImpl = async (request: FindCampaignReq, result: FindCampaignRes, thunkAPI) => {
    result.campaign = await CampaignService.get(request.campaignId);
};

const FindCampaignCtrl = {
    listCampaign: action<FindCampaignReq, FindCampaignRes>(listCampaignImpl, 'FindCampaignCtrlCtrl/listCampaign'),
    getCampaign: action<FindCampaignReq, FindCampaignRes>(getCampaignImpl, 'FindCampaignCtrlCtrl/getCampaign'),
};

export default FindCampaignCtrl;
