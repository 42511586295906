import { action, util } from 'core';
import ClusterService from 'model/cluster/ClusterService';
import { IScreen } from 'model/screen/ScreenDataModel';
import ScreenService from 'model/screen/ScreenService';
import { ScreenStatusMapReq, ScreenStatusMapRes } from './ScreenStatusMapMdl';

const listScreenImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    await request.form?.validateFields();
    const a = await ScreenService.list(-1);
    result.screenList = a.list;
};


const listClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    await request.form?.validateFields();
    result.clusterList = await ClusterService.list();
};


const addClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    await request.form?.validateFields();
    const dataForm = util.removeNonSerialisable(request.form.getFieldsValue());
    result.cluster = await ClusterService.add(dataForm);
};

const saveClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    const { screenStatusMapMdl } = thunkAPI.getState() as any;

    const oldList: IScreen[] = screenStatusMapMdl.oldCluster.screenList;
    const newList: IScreen[] = screenStatusMapMdl.currentCluster.screenList;

    const oldIds = new Set(oldList.map(screen => screen.id));
    const newIds = new Set(newList.map(screen => screen.id));

    const idsToAdd = newList.filter(screen => !oldIds.has(screen.id)).map(screen => screen.id);

    const idsToRemove = oldList.filter(screen => !newIds.has(screen.id)).map(screen => screen.id);


    await ClusterService.addScreen(screenStatusMapMdl.currentCluster, idsToAdd);
    await ClusterService.removeScreen(screenStatusMapMdl.currentCluster, idsToRemove);
};

const getClusterImpl = async (request: ScreenStatusMapReq, result: ScreenStatusMapRes, thunkAPI) => {
    result.cluster = await ClusterService.get(request.clusterId);
};

const ScreenStatusMapCtrl = {
    listScreen: action<ScreenStatusMapReq, ScreenStatusMapRes>(listScreenImpl, 'ScreenStatusMapCtrl/listScreen'),
    listCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(listClusterImpl, 'ScreenStatusMapCtrl/listCluster'),
    addCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(addClusterImpl, 'ScreenStatusMapCtrl/addCluster'),
    saveCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(saveClusterImpl, 'ScreenStatusMapCtrl/saveCluster'),
    getCluster: action<ScreenStatusMapReq, ScreenStatusMapRes>(getClusterImpl, 'ScreenStatusMapCtrl/getCluster'),
};

export default ScreenStatusMapCtrl;
