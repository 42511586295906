import { DeleteOutlined, ForkOutlined, SaveOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Column, PrimaryFilledPanel, RowSpacing, Table } from 'component';
import PrimaryFilledButton from 'component/button/PrimaryFilledButton';
import { useAppDispatch, useExecute } from 'core';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ScreenStatusMapCtrl from '../ScreenStatusMapCtrl';
import { currentClusterSelector, ScreenStatusMapMdl } from '../ScreenStatusMapMdl';
import ShareClusterDialog from './ShareClusterDialog';

const ListScreenPanel = () => {
    const dispatch = useAppDispatch();
    const { execute } = useExecute();
    const currentCluster = useSelector(currentClusterSelector);
    const [visible, setVisible] = useState(false);

    const removeScreen = (screen) => {
        dispatch(ScreenStatusMapMdl.removeScreen(screen));
    };

    const saveCluster = () => {
        execute(ScreenStatusMapCtrl.saveCluster);
    };

    const shareCluster = () => {
        setVisible(true);
    };

    return (
        <PrimaryFilledPanel label={currentCluster?.id ? 'Screen List of ' + currentCluster.name : 'No Cluster Selected'}>
            {currentCluster && (
                <div>
                    <Table data={currentCluster.screenList}>
                        <Column name="id" />
                        <Column name="status" />
                        <Column name="status_last" date />
                        <Column action={removeScreen} icon={<DeleteOutlined />} toolTip="remove" />
                    </Table>
                    <RowSpacing />
                    <div style={{ textAlign: 'right' }}>
                        <Space>
                            {currentCluster.changed && <PrimaryFilledButton name="save" action={saveCluster} icon={<SaveOutlined />} />}
                            {!currentCluster.changed && <PrimaryFilledButton name="share" action={shareCluster} icon={<ForkOutlined rotate={90} />} />}
                        </Space>
                    </div>
                    <ShareClusterDialog visible={visible} setVisible={setVisible} />
                </div>
            )}
        </PrimaryFilledPanel>
    );
};

export default ListScreenPanel;
