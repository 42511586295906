import { Checkbox, CheckboxProps, Col, Row, Space } from 'antd';
import { Block, PrimaryFilledPanel } from 'component';
import { useAppDispatch } from 'core';
import { useSelector } from 'react-redux';
import { ScreenStatusMapMdl, showOfflineSelector, showOnlineSelector } from '../ScreenStatusMapMdl';

const StatusFilterPanel = () => {
    const dispatch = useAppDispatch();
    const showOnline = useSelector(showOnlineSelector);
    const showOffline = useSelector(showOfflineSelector);

    const changeShowOffline: CheckboxProps['onChange'] = (e) => {
        dispatch(ScreenStatusMapMdl.setShowOffline(e.target.checked));
    };

    const changeShowOnline: CheckboxProps['onChange'] = (e) => {
        dispatch(ScreenStatusMapMdl.setShowOnline(e.target.checked));
    };

    return (
        <PrimaryFilledPanel title="status.filter">
            <Block>
                <Space direction="vertical">
                    <Row>
                        <Col span={24}>
                            <Checkbox onChange={changeShowOffline} checked={showOffline}>
                                Show Offline
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Checkbox onChange={changeShowOnline} checked={showOnline}>
                                Show Online
                            </Checkbox>
                        </Col>
                    </Row>
                </Space>
            </Block>
        </PrimaryFilledPanel>
    );
};

export default StatusFilterPanel;
