import { PlaySquareOutlined } from '@ant-design/icons';
import { PageDefinition, ViewContextProvider } from 'core';
import ListContentView from '../uc/listContent/ListContentView';

export const ListContentPage: PageDefinition = {
    key: 'ListContentPage',
    path: '/content',
    toPath: () => '/content',
    icon: <PlaySquareOutlined />,
    menu: 'content',
    view: (
        <ViewContextProvider uc="ListContentUc">
            <ListContentView />
        </ViewContextProvider>
    ),
};

const ContentPageList = [
    ListContentPage, //
];

export default ContentPageList;
