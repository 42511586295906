import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

export interface ILayoutContextProps {
    menuOpen: boolean;
    setMenuOpen: (open: boolean) => void;
}

const LayoutContext = createContext<ILayoutContextProps | undefined>(undefined);

interface LayoutContextProviderProps {
    children: ReactNode;
}

export const LayoutContextProvider: React.FC<LayoutContextProviderProps> = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(() => {
        const saved = localStorage.getItem('menuOpen');
        return saved !== null ? JSON.parse(saved) : false;
    });

    useEffect(() => {
        localStorage.setItem('menuOpen', JSON.stringify(menuOpen));
    }, [menuOpen]);

    return (
        <LayoutContext.Provider
            value={{
                menuOpen,
                setMenuOpen,
            }}
        >
            {children}
        </LayoutContext.Provider>
    );
};

const useLayoutContext = () => {
    const context = useContext(LayoutContext);
    if (context === undefined) {
        throw new Error('useLayoutContext must be used within a LayoutContextProvider');
    }
    return context;
};

export default useLayoutContext;
