import InTableViewAction from 'component/action/InTableViewAction';
import NoOption from 'component/widget/NoOption';
import YesOption from 'component/widget/YesOption';
import { useAppContext, useI18n, util } from 'core';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { STable, STag } from './styles';

const Table = ({
    //
    data,
    id = null,
    rowKey = 'id',
    pagination = null,
    init = 0,
    selectedList = [],
    selectedIndex = null,
    onSelectionChange = null,
    onRowClick = null,
    onPageChange = null,
    showHeader = true,
    children,
    expandable = null,
    selectionType = 'checkbox',
}) => {
    const { i18n } = useI18n();
    const { dateFormat } = useAppContext();
    const [tablePagination, setTablePagination] = useState({} as any);
    const [selectedKeyList, setSelectedKeyList] = useState([]);

    const toDate = (text) => {
        if (_.isEmpty(text)) {
            return '';
        } else if (dayjs.isDayjs(text)) {
            return text.format(dateFormat);
        } else {
            const m = dayjs(text);
            return m.format(dateFormat);
        }
    };

    const toDuration = (text) => {
        if (_.isEmpty(text)) {
            return '';
        } else {
            return dayjs.duration(text).humanize();
        }
    };

    const toNumber = (text) => {
        if (_.isNull(text) || !_.isNumber(text)) {
            return '';
        } else {
            return _.toString(text.toFixed(2)).replace('.', ',');
        }
    };

    const getColumnList = useCallback(() => {
        const columnArray = [];
        React.Children.forEach(children, (c, index) => {
            if (util.nonNul(c)) {
                const c_attributs = {} as any;
                c_attributs.dataIndex = 'c' + index;
                if (c.props.name) {
                    const i = c.props.name.indexOf('.');
                    if (i > 0) {
                        const names = c.props.name.split('.');
                        const entityName = names[names.length - 2];
                        const fieldName = names[names.length - 1];
                        c_attributs.dataIndex = [entityName, fieldName];
                        c_attributs.title = fieldName === 'code' || fieldName === 'label' ? i18n(entityName) : i18n(entityName) + i18n(fieldName);
                    } else {
                        c_attributs.dataIndex = c.props.name;
                        c_attributs.title = i18n(c.props.name);
                    }
                    c_attributs.title = c.props.label ? c.props.label : c_attributs.title;
                    c_attributs.onCell = rowClick;
                    c_attributs.width = c.props.largeur;
                }
                if (c.props.width) {
                    c_attributs.width = c.props.width;
                }
                if (c.props.fixed) {
                    c_attributs.fixed = c.props.fixed;
                }
                if (c.props.date === true) {
                    c_attributs.render = (text) => {
                        return toDate(text);
                    };
                } else if (c.props.reference === true) {
                    c_attributs.render = (text) => {
                        return text?.label ? text.label : text?.code ? '[' + text.code + ']' : '';
                    };
                } else if (c.props.number === true) {
                    c_attributs.render = (text) => {
                        return toNumber(text);
                    };
                } else if (c.props.duration === true) {
                    c_attributs.render = (text) => {
                        return toDuration(text);
                    };
                } else if (c.props.boolean === true) {
                    c_attributs.render = (text) => {
                        return util.nonNul(text) && text ? <YesOption /> : <NoOption />;
                    };
                } else if (c.props.code === true) {
                    c_attributs.render = (text) => {
                        return i18n(text);
                    };
                } else if (c.props.tag === true) {
                    c_attributs.render = (text) => <STag>{text}</STag>;
                } else if (c.props.render === true) {
                    c_attributs.render = c.props.content;
                } else if (c.props.custom === true) {
                    c_attributs.onCell = (value, idx) => {
                        const a = (evt) => {
                            evt.preventDefault();
                            evt.stopPropagation();
                            c.props.siClic(value);
                        };
                        return {
                            onClick: c.props.siClic ? a : null,
                            colSpan: c.props.colSpan ? c.props.colSpan(idx) : 1,
                            rowSpan: c.props.rowSpan ? c.props.rowSpan(idx) : 1,
                        };
                    };

                    c_attributs.render = c.props.content;
                } else if (c.props.action) {
                    c_attributs.onCell = (value, idx) => {
                        return {
                            onClick: (evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                if (c.props.action && idx !== selectedIndex) {
                                    c.props.action(value, idx);
                                }
                            },
                        };
                    };
                    c_attributs.render = () => {
                        return <InTableViewAction name={c.props.name} icon={c.props.icon} toolTip={c.props.toolTip} />;
                    };
                    c_attributs.title = '';
                    c_attributs.width = 42;
                    c_attributs.className = 'actionColumn';
                }
                columnArray.push(c_attributs);
            }
        });
        return columnArray;
    }, [children]);

    const handleTableChange = (pagination) => {
        if (onPageChange) {
            onPageChange(pagination.current);
        }
    };

    useEffect(() => {
        if (pagination) {
            setTablePagination({ current: pagination.currentPage, pageSize: pagination.rowPerPage, total: pagination.totalRow, showSizeChanger: false });
        } else {
            setTablePagination(false);
        }
    }, [pagination]);

    const rowClick = (record, index) => {
        return {
            onClick: () => {
                if (onRowClick) {
                    onRowClick(record, index);
                }
            },
        };
    };

    const getRowClassName = (record, index) => {
        return selectedIndex === index ? 'selected' : null;
    };

    useEffect(() => {
        if (onSelectionChange) {
            setSelectedKeyList([]);
        }
    }, [init]);

    useEffect(() => {
        if (onSelectionChange) {
            setSelectedKeyList(selectedList);
        }
    }, [selectedList.length]);

    const getRowSelection = () => {
        if (onSelectionChange) {
            const a = {
                type: selectionType,
                selectedRowKeys: selectedKeyList,
                onChange: (selectedRowKeys: React.Key[], selectedRows: []) => {
                    onSelectionChange(selectedRows);
                    setSelectedKeyList(selectedRowKeys);
                },
            } as any;
            return a;
        }
        return null;
    };

    return (
        <STable //
            id={id}
            columns={getColumnList()}
            showHeader={showHeader}
            bordered
            size="small"
            dataSource={data}
            rowKey={rowKey}
            onChange={handleTableChange}
            pagination={tablePagination}
            rowClassName={getRowClassName}
            rowSelection={getRowSelection()}
            expandable={expandable}
        ></STable>
    );
};

export default Table;
