import _ from 'lodash';
import useAppContext from '../context/AppContext';
import useAuthContext from './AuthContext';

const useHasRight = (action): boolean => {
    const appContext = useAppContext();
    const { role } = useAuthContext();
    const inRole = _.includes(appContext.permissionMap[role], action);
    if (!inRole) {
        console.log('NO RIGHT FOR =========>' + action);
    }
    return true;//@TODO
};

export default useHasRight;
