import { RoleAreaType } from 'core';
import AdminModuleList from './admin/modules';
import UserModuleList from './user/modules';

const roleAreaMap: Record<string, RoleAreaType> = {
    ADMIN: {
        moduleList: AdminModuleList,
    },
    USER: {
        moduleList: UserModuleList,
    },
};

export default roleAreaMap;