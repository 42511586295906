import useAuthContext from 'core/auth/AuthContext';
import { AppConfigType } from 'core/context/AppContext';
import _ from 'lodash';
import { createContext, useContext, useEffect } from 'react';
import useAppDispatch from '../redux/useAppDispatch';
import { ModuleDefinition } from '../routes/ModuleDefinition';
import { I18nMdl } from './I18nMdl';

export interface II18nContextProps {
    config: AppConfigType;
}

const I18nContext = createContext<II18nContextProps | undefined>(undefined);

interface I18nContextProviderProps {
    config: AppConfigType;
    children: React.ReactNode;
}

export const I18nContextProvider: React.FC<I18nContextProviderProps> = ({ config, children }: { config: AppConfigType; children: React.ReactNode }) => {
    const { role } = useAuthContext();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (role) {
            const roleArea = config.roleAreaMap[role];
            const moduleList = roleArea?.moduleList;
            const sum = _.reduce(
                moduleList,
                (acc, module) => {
                    const moduleI18N = mergeI18N(module);
                    return { ...acc, ...moduleI18N };
                },
                {}
            );

            dispatch(I18nMdl.extendLabelMap(sum));
        }
    }, [role, config]);

    return <I18nContext.Provider value={{ config }}>{children}</I18nContext.Provider>;
};

const mergeI18N = (module: ModuleDefinition) => {
    let result = { ...module.i18nMap };

    if (module.subModuleList?.length) {
        for (const sousModule of module.subModuleList) {
            const sousModuleI18N = mergeI18N(sousModule);
            result = { ...result, ...sousModuleI18N };
        }
    }

    return result;
};
const useI18nContext = () => {
    const context = useContext(I18nContext);
    if (context === undefined) {
        throw new Error('useI18nContext must be used within a I18nContextProvider');
    }
    return context;
};

export default useI18nContext;
