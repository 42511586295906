import { SearchOutlined } from '@ant-design/icons';
import { PageDefinition, ViewContextProvider } from 'core';
import FindCampaignView from '../uc/findCampaign/FindCampaignView';

export const FindCampaignPage: PageDefinition = {
    key: 'FindCampaignPage',
    path: '/booking/findCampaign',
    toPath: () => '/booking/findCampaign',
    icon: <SearchOutlined />,
    menu: 'booking',
    view: (
        <ViewContextProvider uc="FindCampaignUc">
            <FindCampaignView />
        </ViewContextProvider>
    ),
};

const CampaignPageList = [
    FindCampaignPage, //
];

export default CampaignPageList;
