import { Action, combineReducers, configureStore, Reducer, Store, ThunkAction } from '@reduxjs/toolkit';
import I18nMdl from '../i18n/I18nMdl';
import MessageMdl from '../message/MessageMdl';
import waxantMiddleWare from './waxantMiddleWare';

interface ReducerMap {
    [key: string]: Reducer;
}

const staticReducers: ReducerMap = {
    messageMdl: MessageMdl,
    i18nMdl: I18nMdl,
};

const store = configureStore({
    reducer: combineReducers(staticReducers),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(waxantMiddleWare),
});

const getStore = () => {
    return store;
};

export const registerReducer = (newReducers: ReducerMap): Store<any> => {
    store.replaceReducer(combineReducers({ ...staticReducers, ...newReducers }));
    return store;
};

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, IRootState, unknown, Action>;

export default getStore;
