import { InfoDialog } from 'component';
import { useSelector } from 'react-redux';
import { currentClusterSelector } from '../ScreenStatusMapMdl';

const ShareClusterDialog = ({ visible, setVisible }) => {
    const currentCluster = useSelector(currentClusterSelector);
    const close = () => {
        setVisible(false);
    };

    return (
        <InfoDialog name="ShareClusterDialog" visible={visible} closeAction={close}>
            <div>Share this link with your team to collaborate on this cluster:</div>
            <a href={'https://www.doohboost.app/cluster/{currentCluster.id}/map'} target="_blank">
                https://www.doohboost.app/cluster/{currentCluster.id}/map
            </a>
        </InfoDialog>
    );
};
export default ShareClusterDialog;
