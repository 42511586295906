import { ConfigProvider, theme } from 'antd';
import BaseBouton, { BtnProps } from './BaseButton';
import DisabledFilledButton from './DisabledFilledButton';

const PrimaryFilledButton = (props: BtnProps) => {
    const { token } = theme.useToken();
    const components = {
        Button: {
            defaultColor: '#fff',
            defaultBg: token.colorPrimary,
            defaultBorderColor: token.colorPrimary,
            defaultHoverColor: '#fff',
            defaultHoverBg: token.colorWarning,
            defaultHoverBorderColor: token.colorWarning,
        },
    };

    if (props.disabled) {
        return <DisabledFilledButton {...props} />;
    }
    return (
        <ConfigProvider theme={{ components }}>
            <BaseBouton {...props} />
        </ConfigProvider>
    );
};

export default PrimaryFilledButton;
