import { Col, Form, Row, TimePicker } from 'antd';
import { ChampDate, ChampOuiNon, ChampTexte, ConfirmDialog, Formulaire, RowSpacing, useOnChange } from 'component';
import PrimaryOutlinedPanel from 'component/container/PrimaryOutlinedPanel';
import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedScreenListSeclector } from '../FindScreenMdl';

const UpdateDayPartDialog = ({ visible, setVisible }) => {
    const selectedScreenList = useSelector(selectedScreenListSeclector);
    const [saveCluster, setSaveCluster] = useState(false);
    const [dayPartTable, setDayPartTable] = useState(null);
    const [form] = Form.useForm();

    const close = () => {
        setVisible(false);
    };
    const confirm = () => {
        setVisible(false);
    };

    useOnChange('createCluster', form, (value) => {
        setSaveCluster(value);
    });

    //
    return (
        <ConfirmDialog name="updateDayPart" visible={visible} closeAction={close} confirmAction={confirm} width={1200}>
            <Row gutter={20}>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Day Part to Add">
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampTexte nom="name" />
                            <ChampTexte nom="weight" />
                            <ChampDate nom="startDate" />
                            <ChampDate nom="endDate" />
                        </Formulaire>
                        <RowSpacing />
                        <Row>
                            <Col flex="131px">Monday</Col>
                            <Col flex="154px">
                                <Form.Item name="mondayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="mondayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="131px">Tuesday</Col>
                            <Col flex="154px">
                                <Form.Item name="tuesdayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="tuesdayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="131px">Wednesday</Col>
                            <Col flex="154px">
                                <Form.Item name="wednesdayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="wednesdayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="131px">Thursday</Col>
                            <Col flex="154px">
                                <Form.Item name="thursdayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="thursdayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="131px">Friday</Col>
                            <Col flex="154px">
                                <Form.Item name="fridayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="fridayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="131px">Saturday</Col>
                            <Col flex="154px">
                                <Form.Item name="saturdayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="saturdayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="131px">Sunday</Col>
                            <Col flex="154px">
                                <Form.Item name="sundayStart">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                            <Col flex="154px">
                                <Form.Item name="sundayEnd">
                                    <TimePicker />
                                </Form.Item>
                            </Col>
                        </Row>
                    </PrimaryOutlinedPanel>
                </Col>
                <Col span="12">
                    <PrimaryOutlinedPanel label="Selected Screen List">
                        <Table data={selectedScreenList}>
                            <Column name="id" />
                            <Column name="name" />
                            <Column name="status" />
                            <Column name="zipcode" />
                        </Table>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
            <RowSpacing />
            <Row>
                <Col span="24">
                    <PrimaryOutlinedPanel>
                        <Formulaire form={form} nombreColonne={2}>
                            <ChampOuiNon nom="createCluster" />
                            <ChampTexte nom="clusterName" invisible={!saveCluster} />
                        </Formulaire>
                    </PrimaryOutlinedPanel>
                </Col>
            </Row>
        </ConfirmDialog>
    );
};

export default UpdateDayPartDialog;
