import { IErrorDescriptor, IErrorMessage, IInfoDescriptor } from 'core/message/MessageDataModel';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import ErrorMapper from './ErrorMapper';
import { i18nErrorMapSelector, i18nLabelMapSelector, i18nMessageMapSelector } from './I18nMdl';
import InfoMapper from './InfoMapper';
import { default as LabelMapper } from './LabelMapper';

const useI18n = () => {
    const i18nLabelMap = useSelector(i18nLabelMapSelector);
    const i18nErrorMap = useSelector(i18nErrorMapSelector);
    const i18nMessageMap = useSelector(i18nMessageMapSelector);
    const i18n = useCallback(
        (key: string): string => {
            return LabelMapper.label(key, i18nLabelMap);
        },
        [i18nLabelMap]
    );

    const i18nError = useCallback(
        (key: IErrorDescriptor): IErrorMessage => {
            return ErrorMapper.get(key, i18nErrorMap, i18nLabelMap);
        },
        [i18nErrorMap]
    );

    const i18nInfo = useCallback(
        (key: IInfoDescriptor): string => {
            return InfoMapper.get(key, i18nMessageMap, i18nLabelMap);
        },
        [i18nLabelMap, i18nMessageMap]
    );

    const i18nLog = useCallback(
        (key: string): string => {
            return LabelMapper.action(key, i18nLabelMap);
        },
        [i18nLabelMap]
    );

    return { i18n, i18nError, i18nInfo, i18nLog };
};

export default useI18n;
