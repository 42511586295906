import Column from 'component/table/Column';
import Table from 'component/table/Table';
import { useExecute } from 'core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ListContentCtrl from '../ListContentCtrl';
import { contentListSelector } from '../ListContentMdl';

const ContentTable = () => {
    const contentList = useSelector(contentListSelector);
    const { execute } = useExecute();

    useEffect(() => {
        execute(ListContentCtrl.listContent, { currentPage: 1 });
    }, []);

    const onPageChange = (newPage) => {
        execute(ListContentCtrl.listContent, { currentPage: newPage });
    };

    return (
        <Table rowKey="bscid" data={contentList.list} pagination={contentList.pagination} onPageChange={onPageChange}>
            <Column name="bscid" />
            <Column name="container_id" />
            <Column name="name" />
            <Column name="mime" />
            <Column name="size" />
            <Column name="status" />
            <Column name="creation_tm" date />
        </Table>
    );
};

export default ContentTable;
