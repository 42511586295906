import { createContext, useContext, useEffect, useState } from 'react';
import SessionStorage from '../util/session.storage';
import util from '../util/util';
import { IUser } from './AuthDataModel';

export interface IAuthContextProps {
    user: IUser;
    init?: (user: IUser) => void;
    role: string;
}

const AuthContext = createContext({} as IAuthContextProps);

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);

    const init = (user: IUser) => {
        SessionStorage.setUser(user);
        setUser(user);
        setRole(user.role);
    };

    useEffect(() => {
        if (util.estVide(user)) {
            const u = SessionStorage.getUser();
            if (u && u.token) {
                const hasTokenExpired = new Date(parseInt(u.creationTime) + parseInt(u.expiryTime) * 1000) < new Date();
                if ((u.expiryTime && u.creationTime) || !hasTokenExpired) {
                    setUser(u);
                    setRole(u.role);
                } else {
                    SessionStorage.clear();
                }
            } else {
                SessionStorage.clear();
            }
        }
    }, [user]);

    return <AuthContext.Provider value={{ user, role, init }}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuthContext must be used within a AuthContext');
    }
    return context;
};

export default useAuthContext;
